import React from 'react'

const CoppyIcon = ({ height, width }) => {
  return (
    <svg height={height} width={width} viewBox="0 0 24 24" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.6501 4.2629L16.4635 4.3642C18.3408 4.59801 19.75 6.19359 19.75 8.08545V18.5749C19.75 20.4118 18.3817 21.961 16.5589 22.188C13.5313 22.5651 10.4687 22.5651 7.44107 22.188C5.61826 21.961 4.25 20.4118 4.25 18.5749V8.08545C4.25 6.19359 5.6592 4.59801 7.53655 4.3642L8.3499 4.2629C8.67208 3.10206 9.73654 2.25 11 2.25H13C14.2635 2.25 15.3279 3.10206 15.6501 4.2629ZM8.25 5.78693L7.72193 5.8527C6.59552 5.99299 5.75 6.95034 5.75 8.08545V18.5749C5.75 19.655 6.55458 20.566 7.62645 20.6995C10.5309 21.0612 13.4691 21.0612 16.3735 20.6995C17.4454 20.566 18.25 19.655 18.25 18.5749V8.08545C18.25 6.95034 17.4045 5.99299 16.2781 5.8527L15.75 5.78693V7C15.75 7.41421 15.4142 7.75 15 7.75H9C8.58579 7.75 8.25 7.41421 8.25 7V5.78693ZM9.75 5C9.75 4.30964 10.3096 3.75 11 3.75H13C13.6904 3.75 14.25 4.30964 14.25 5V6.25H9.75V5Z"
        fill="#BBBBBB"
      />
      <path
        d="M15.75 11.75C15.75 11.3358 15.4142 11 15 11H9C8.58579 11 8.25 11.3358 8.25 11.75C8.25 12.1642 8.58579 12.5 9 12.5H15C15.4142 12.5 15.75 12.1642 15.75 11.75Z"
        fill="#BBBBBB"
      />
      <path
        d="M14.75 14.75C14.75 14.3358 14.4142 14 14 14H9C8.58579 14 8.25 14.3358 8.25 14.75C8.25 15.1642 8.58579 15.5 9 15.5H14C14.4142 15.5 14.75 15.1642 14.75 14.75Z"
        fill="#BBBBBB"
      />
    </svg>
  )
}

export default CoppyIcon
