import axiosClient from './axiosClient'
import urlApi from './url'

const baseUrl = `${process.env.REACT_APP_API_URL}`

const scenarioApi = {
  get: (params) => {
    return axiosClient.get(`${baseUrl}api/v1.0/scenario`, { params })
  },
  deleteScenario: (payload) => {
    return axiosClient
      .delete(
        `${baseUrl}api/v1.0/campaign/${payload?.id}/scenario/${payload?.scenarioId}`,
        {
          data: payload,
        }
      )
      .then((res) => res.data)
  },
}

export default scenarioApi
