import React from 'react'
import Button from '@material-ui/core/Button'
import Flex from '../Flex'
import { useTranslation } from "react-i18next";

const ButtonLoading = ({ title, isLoading, ...rest }) => {
      const { t } = useTranslation();

  return (
    <Button {...rest}>
      <Flex align="center" justify="center">
        {isLoading && (
          <div className="spiner_sm spinner-grow mr-2 " role="status">
            <span className="sr-only">{t("noti.loading")}</span>
          </div>
        )}
        <div className="my-0 d-flex align-items-center">{title}</div>
      </Flex>
    </Button>
  );
}

export default ButtonLoading
