import { Card } from '@material-ui/core'
import { useEffect, useMemo, useState } from 'react'
import { Doughnut } from 'react-chartjs-2'
import homeApi from '../../../../api/homeApi'
import BaseDatePicker2 from 'components/Form/BaseDatePicker2'
import { useForm } from 'react-hook-form'
import moment from 'moment'
import Flex from '../../../../components/Flex'
import { useTranslation } from 'react-i18next'
import LegendItem from './LegendItem'

const ScenarioChart = ({ style, ...res }) => {
  const [data, setData] = useState(null)
  const { t } = useTranslation()

  const { control, watch } = useForm({
    defaultValues: {
      date: [moment().subtract(30, 'days').toDate(), new Date()],
    },
  })

  useEffect(() => {
    ;(async () => {
      try {
        const date = watch('date')
        const res = await homeApi.getScenarioStatistic({
          from: moment(date[0]).format('YYYY-MM-DD'),
          to: moment(date[1]).format('YYYY-MM-DD'),
        })
        setData(res)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [watch('date')])

  const totalScenarios = data?.totalScenarios || 0

  const dataScenarios = useMemo(() => {
    return {
      labels: [
        `${t('dashboard.scenario_stopped')}`,
        `${t('dashboard.scenario_not_started')}`,
        `${t('dashboard.scenario_completed')}`,
        `${t('dashboard.scenario_running')}`,
      ],
      datasets: [
        {
          data: [
            data?.totalScenarioCancel || 0,
            data?.totalScenarioPending || 0,
            data?.totalScenarioDone || 0,
            data?.totalScenarioRunning || 0,
          ],
          backgroundColor: ['#ff6f91', '#ffb74d', '#81c784', '#64b5f6'],
          hoverBackgroundColor: ['#ff3d67', '#ff9800', '#4caf50', '#1e88e5'],
        },
      ],
    }
  }, [data, t])

  const doughnutOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
  }

  const textOverlay = {
    id: 'text-center',
    beforeDatasetsDraw(chart) {
      const { ctx } = chart
      ctx.save()
      ctx.font = '600 14px sans-serif'
      ctx.fillStyle = '#000000de'
      ctx.textAlign = 'center'
      ctx.textBaseline = 'middle'
      ctx.fillText(
        `${totalScenarios}`,
        chart.getDatasetMeta(0).data[0].x,
        chart.getDatasetMeta(0).data[0].y
      )
    },
  }

  const isEmpty = totalScenarios === 0

  return (
    <Card style={{ ...style, padding: '16px', borderRadius: '8px' }} {...res}>
      <Flex
        align="flex-start"
        style={{
          justifyContent: 'space-between',
          width: '100%',
          marginBottom: '16px',
        }}
      >
        <h3 style={{ margin: 0, fontSize: '18px', fontWeight: '600' }}>
          {t('dashboard.scenario')}
        </h3>

        <Flex
          align="flex-end"
          justify="flex-end"
          column
          style={{ textAlign: 'right' }}
        >
          <BaseDatePicker2
            name={'date'}
            control={control}
            defaultValue={[null, null]}
          />
          <p
            style={{
              fontSize: '12px',
              fontStyle: 'italic',
              marginTop: '4px',
              color: '#8f8f8f',
            }}
          >
            {t('dashboard.script_creation_date_range')}
          </p>
        </Flex>
      </Flex>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        {isEmpty ? (
          <Flex
            align="center"
            justify="center"
            style={{ height: '150px', width: '100%' }}
          >
            <p style={{ fontSize: '16px', color: '#9095a0' }}>
              {t('dashboard.no_data')}
            </p>
          </Flex>
        ) : (
          <Flex
            align="center"
            justify="space-between"
            style={{ width: '100%' }}
          >
            <div style={{ width: '60%', paddingRight: '16px' }}>
              <Doughnut
                data={dataScenarios}
                options={doughnutOptions}
                plugins={[textOverlay]}
              />
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'center',
                flexDirection: 'column',
                width: '40%',
              }}
            >
              <LegendItem
                legendColor={dataScenarios.datasets[0].backgroundColor[0]}
                number={data?.totalScenarioCancel}
                percent={`${parseFloat(
                  (data?.totalScenarioCancel / totalScenarios) * 100
                ).toFixed(2)} %`}
                title={t('dashboard.scenario_stopped')}
              />

              <LegendItem
                legendColor={dataScenarios.datasets[0].backgroundColor[1]}
                number={data?.totalScenarioPending}
                percent={`${parseFloat(
                  (data?.totalScenarioPending / totalScenarios) * 100
                ).toFixed(2)} %`}
                title={t('dashboard.scenario_not_started')}
              />

              <LegendItem
                legendColor={dataScenarios.datasets[0].backgroundColor[2]}
                number={data?.totalScenarioDone}
                percent={`${parseFloat(
                  (data?.totalScenarioDone / totalScenarios) * 100
                ).toFixed(2)} %`}
                title={t('dashboard.scenario_completed')}
              />

              <LegendItem
                legendColor={dataScenarios.datasets[0].backgroundColor[3]}
                number={data?.totalScenarioRunning}
                percent={`${parseFloat(
                  (data?.totalScenarioRunning / totalScenarios) * 100
                ).toFixed(2)} %`}
                title={t('dashboard.scenario_running')}
              />
            </div>
          </Flex>
        )}
      </div>
    </Card>
  )
}

export default ScenarioChart
