import React, { useEffect, useMemo, useState } from 'react'
import homeApi from '../../../../api/homeApi'
import { Card } from '@material-ui/core'
import { Doughnut } from 'react-chartjs-2'
import BaseDatePicker2 from '../../../../components/Form/BaseDatePicker2'
import { useForm } from 'react-hook-form'
import moment from 'moment'
import Flex from '../../../../components/Flex'
import { useTranslation } from 'react-i18next'
import LegendItem from './LegendItem'

const CustomerChart = ({ style, ...res }) => {
  const [data, setData] = useState(null)
  const { t } = useTranslation()

  const { control, watch } = useForm({
    defaultValues: {
      date: [moment().subtract(30, 'days').toDate(), new Date()],
    },
  })

  useEffect(() => {
    ;(async () => {
      try {
        const date = watch('date')
        const res = await homeApi.getCustomerStatistic({
          from: moment(date[0]).format('YYYY-MM-DD'),
          to: moment(date[1]).format('YYYY-MM-DD'),
        })
        setData(res)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [watch('date')])

  const totalCustomers = data?.totalCustomers || 0

  const dataCustomers = useMemo(() => {
    return {
      labels: [
        `${t('dashboard.failed_customer')}`,
        `${t('dashboard.customer_not_sent')}`,
        `${t('dashboard.successful_customer')}`,
      ],
      datasets: [
        {
          data: [
            data?.totalCustomersFail || 0,
            data?.totalCustomersNotSend || 0,
            data?.totalCustomersSuccess || 0,
          ],
          backgroundColor: ['#ff6f91', '#ffb74d', '#81c784'],
          hoverBackgroundColor: ['#ff3d67', '#ff9800', '#4caf50'],
        },
      ],
    }
  }, [data, t])

  const doughnutOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
  }

  const textOverlay = {
    id: 'text-center',
    beforeDatasetsDraw(chart) {
      const { ctx } = chart
      ctx.save()
      ctx.font = '600 14px sans-serif'
      ctx.fillStyle = '#000000de'
      ctx.textAlign = 'center'
      ctx.textBaseline = 'middle'
      ctx.fillText(
        `${totalCustomers}`,
        chart.getDatasetMeta(0).data[0].x,
        chart.getDatasetMeta(0).data[0].y
      )
    },
  }

  const isEmpty = totalCustomers === 0

  return (
    <Card style={{ ...style, padding: '16px', borderRadius: '8px' }} {...res}>
      <Flex
        align="flex-start"
        style={{
          justifyContent: 'space-between',
          width: '100%',
          marginBottom: '16px',
        }}
      >
        <h3 style={{ margin: 0, fontSize: '18px', fontWeight: '600' }}>
          {t('dashboard.customer')}
        </h3>

        <Flex
          align="flex-end"
          justify="flex-end"
          column
          style={{ textAlign: 'right' }}
        >
          <BaseDatePicker2
            name={'date'}
            control={control}
            defaultValue={[null, null]}
          />
          <p
            style={{
              fontSize: '12px',
              fontStyle: 'italic',
              marginTop: '4px',
              color: '#8f8f8f',
            }}
          >
            {t('dashboard.customer_created_from_to')}
          </p>
        </Flex>
      </Flex>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        {isEmpty ? (
          <Flex
            align="center"
            justify="center"
            style={{ height: '150px', width: '100%' }}
          >
            <p style={{ fontSize: '16px', color: '#9095a0' }}>
              {t('dashboard.no_data')}
            </p>
          </Flex>
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <div style={{ width: '60%', paddingRight: '16px' }}>
              <Doughnut
                data={dataCustomers}
                options={doughnutOptions}
                plugins={[textOverlay]}
              />
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'center',
                flexDirection: 'column',
                width: '40%',
              }}
            >
              <LegendItem
                legendColor={dataCustomers.datasets[0].backgroundColor[0]}
                number={data?.totalCustomersFail}
                percent={`${parseFloat(
                  (data?.totalCustomersFail / totalCustomers) * 100
                ).toFixed(2)} %`}
                title={t('dashboard.failed_customer')}
              />

              <LegendItem
                legendColor={dataCustomers.datasets[0].backgroundColor[1]}
                number={data?.totalCustomersNotSend}
                percent={`${parseFloat(
                  (data?.totalCustomersNotSend / totalCustomers) * 100
                ).toFixed(2)} %`}
                title={t('dashboard.customer_not_sent')}
              />

              <LegendItem
                legendColor={dataCustomers.datasets[0].backgroundColor[2]}
                number={data?.totalCustomersSuccess}
                percent={`${parseFloat(
                  (data?.totalCustomersSuccess / totalCustomers) * 100
                ).toFixed(2)} %`}
                title={t('dashboard.successful_customer')}
              />
            </div>
          </div>
        )}
      </div>
    </Card>
  )
}

export default CustomerChart
