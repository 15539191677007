import customerApi from '../../api/customerApi'
import fanpageApi from '../../api/fanpageApi'
import { setLoading } from '../App/AppReducer'
import types from '../../constants/types'
import {
  setCustomer,
  setEdit,
  setDetail,
  setFanpageSelected,
  setTagFacebookSelectedAll,
  setSelectTagFacebook,
  setLoadingImport,
  setImportSuccess,
  setFanPageDetail,
  setCustomerAll,
  setCustomerCheck,
  setRestCustomerCheck,
  setCreateCustomerSucss,
  setMessageImport,
  startAddTags,
  addTagsSuccess,
  addTagsFailed,
  importResult,
} from './CustomerReducer'

export function getCustomer(params) {
  return (dispatch) => {
    dispatch(setLoading(true))
    return customerApi.get(params).then(
      (res) => {
        dispatch(setCustomer(res))
        dispatch(setLoading(false))
      },
      (error) => {
        dispatch(setLoading(false))
      }
    )
  }
}
export function getCustomerAll(params) {
  return (dispatch) => {
    dispatch(setLoading(true))
    return customerApi.get(params).then(
      (res) => {
        dispatch(setCustomerAll(res))
        dispatch(setLoading(false))
      },
      (error) => {
        dispatch(setLoading(false))
      }
    )
  }
}

function saveAs(blob, fileName) {
  var url = window.URL.createObjectURL(blob)

  var anchorElem = document.createElement('a')
  anchorElem.style = 'display: none'
  anchorElem.href = url
  anchorElem.download = fileName

  document.body.appendChild(anchorElem)
  anchorElem.click()

  document.body.removeChild(anchorElem)

  // On Edge, revokeObjectURL should be called only after
  // a.click() has completed, atleast on EdgeHTML 15.15048
  setTimeout(function () {
    window.URL.revokeObjectURL(url)
  }, 1000)
}

export function onExport(params) {
  // console.log('params', params)
  return (dispatch) => {
    dispatch(setLoading(true))
    return customerApi
      .export(`${params?.fanpageSelected.id}/export`, {
        excludes: params.exclude,
      })
      .then(
        (res) => {
          dispatch(setLoading(false))
          const filename = `Customer_${
            params?.fanpageSelected.name
          }_${Date.now()}`
          const blob = new Blob([res], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
          return saveAs(blob, filename + '.xlsx')
        },
        (error) => {
          dispatch(setLoading(false))
        }
      )
  }
}

export function detail(params) {
  return (dispatch) => {
    dispatch(setDetail(params))
  }
}
export function edit(params) {
  return (dispatch) => {
    return customerApi.edit(params).then(
      (res) => {
        return res
      },
      (error) => {
        throw error
      }
    )
  }
}
export function downloadExcel() {
  return (dispatch) => {
    return customerApi.downloadExcel().then(
      (res) => {
        // console.log('res downloadExcel', res);
      },
      (error) => {
        throw error
      }
    )
  }
}

export function onSubmitAddTag(params) {
  return (dispatch) => {
    return fanpageApi.fanPageDetail(params).then(
      (res) => {
        dispatch(setFanPageDetail(res))
      },
      (error) => {
        throw error
      }
    )
  }
}

function parseJSONfromParam(inputString) {
  // Split the string based on semicolon
  const keyValuePairs = inputString.split(';')

  // Create an object to store key-value pairs
  const resultObject = {}

  // Iterate through each key-value pair and add it to the object
  keyValuePairs.forEach((pair) => {
    const [key, value] = pair.split('==')
    if (value) {
      resultObject[key.trim()] = value.trim()
    } else {
      const split = pair.split('=')
      resultObject[split[0].trim()] = split.slice(1).join('=')
    }
  })

  // Convert the result object to JSON
  const resultJSON = JSON.stringify(resultObject)

  return resultJSON
}
export function onCreate(paramsFilter, rows, params, data) {
  const searchParam = new URLSearchParams(window.location.search)
  const getQuery = searchParam.get('query')
  let excludeArr = []
  rows.map((item) => {
    if (item.checked) {
      return excludeArr.push(item.uid)
    }
  })

  const createCustomerParams = {
    ...params,
    totalCus: data.totalElements - rows.length,
    query: `${
      getQuery ? parseJSONfromParam(getQuery) : `{"pageId": "${params.pageId}"}`
    }`,
    queryString: getQuery || `pageId==${params.pageId}`,
    exclude: excludeArr,
    bombotTags: [],
    totalMes: 0,
    delayTime: 5,
    status: 'pending',
  }

  return (dispatch) => {
    dispatch(setLoading(true))
    return customerApi.addCampaignNoScenario(createCustomerParams).then(
      (res) => {
        dispatch(setLoading(false))
        window.open(
          types.URL.FACEBOOK +
            `&token=${localStorage.getItem('token') || null}&campaign=${
              res?.id
            }&pageId=${res.pageId}`,
          '_blank'
        )
      },
      (error) => {
        dispatch(setLoading(false))
      }
    )
  }
}

export function fanpageSelected(params) {
  return (dispatch) => {
    dispatch(setFanpageSelected(params))
  }
}

export function onCustomerCheck(params) {
  return (dispatch) => {
    dispatch(setCustomerCheck(params))
  }
}
export function onCustomerCheckReset(params) {
  return (dispatch) => {
    dispatch(setRestCustomerCheck(params))
  }
}

export function tagFacebookSelectedAll(params) {
  return (dispatch) => {
    dispatch(setTagFacebookSelectedAll(params))
  }
}
export function selectTagFacebook(params) {
  return (dispatch) => {
    dispatch(setSelectTagFacebook(params))
  }
}
export function fileUpload(params) {
  return (dispatch) => {
    dispatch(setImportSuccess(false))
    dispatch(setLoadingImport(true))
    return customerApi.import(params).then(
      (res) => {
        dispatch(setLoadingImport(false))
        dispatch(setImportSuccess(true))
        dispatch(importResult(res.data))
        // dispatch(setCustomer(res));
      },
      (error) => {
        dispatch(setLoadingImport(false))
        dispatch(setImportSuccess(false))
        dispatch(setMessageImport(error.response.data.error))
      }
    )

    // const url = 'http://example.com/file-upload';
    // const formData = new FormData();
    // formData.append('file',file)
    // const config = {
    //     headers: {
    //         'content-type': 'multipart/form-data'
    //     }
    // }
    // return  post(url, formData,config)
  }
}
export function resetCustomer() {
  return (dispatch) => {
    dispatch(setCustomer({}))
  }
}

export function onAddTags(params) {
  return (dispatch) => {
    dispatch(startAddTags())
    return fanpageApi.fanPageDetail(params).then(
      (res) => {
        dispatch(addTagsSuccess(res))
      },
      (error) => {
        dispatch(addTagsFailed(error))
        throw error
      }
    )
  }
}
