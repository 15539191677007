import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'
import AddAlertIcon from '@material-ui/icons/AddAlert'
import DehazeIcon from '@material-ui/icons/Dehaze'
import ForumIcon from '@material-ui/icons/Forum'
import GetAppIcon from '@material-ui/icons/GetApp'
import cn from 'classnames'
import Flex from 'components/Flex'
import { CalendarIcon, Flag, Gauge, Monitor, Tags, UserIcon } from 'lucide-react'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import ButtonComponent from '../../components/Button'
import types from '../../constants/types'
import IMAGES from '../../images'

const useStyles = makeStyles({
  root: {
    width: 250,
  },
  list: {
    width: 250,
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  listDehaze: {
    width: 60,
    height: '100%',
    position: 'relative',
    overflowX: 'hidden',
  },
  fullList: {
    width: 'auto',
  },
  logoDehaze: {
    textAlign: 'right',
    '& img': {
      width: 'auto',
      display: 'inline-block',
      margin: '45px auto 30px',
      position: 'relative',
      right: 8,
    },
  },
  logo: {
    textAlign: 'center',
    '& img': {
      width: '50%',
      display: 'inline-block',
      margin: '30px auto',
    },
  },
  text: {
    '& span': {
      fontSize: 15,
    },
  },
  button: {
    margin: '5px 0px',
    display: 'inline-block',
    width: 'calc(100% - 30px)',
  },
  bottom: {
    textAlign: 'center',
    position: 'absolute',
    bottom: '0px',
  },
  wrapperSidebar: {
    width: 250,
  },
  active: {
    background: '#05AE34',
    color: 'white',
    '& .MuiListItemIcon-root': {
      color: 'white',
    },
  },
  dehazeIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 12,
    paddingRight: 12,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  dehazeIconDehaze: {
    top: '50%',
    left: '75px',
    position: 'fixed',
    transform: 'translateY(-50%)',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  logoIconDehaze: {
    display: 'none',
  },
  dehazeList: {
    display: 'none',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
  },
})

export default function TemporaryDrawer(props) {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()
  const { onMenu, getMenu, onDehaze, dehaze } = props
  const [state, setState] = React.useState({
    top: false,
    left: true,
    bottom: false,
    right: false,
  })
  const [activeMenu, setActiveMenu] = React.useState(null)
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setState({ ...state, [anchor]: open })
  }
  const menu = [
    {
      name: t(types.MENU.HOME.NAME),
      icon: <Monitor />,
      link: types.MENU.HOME.LINK,
      id: 'overview',
    },
    {
      name: t(types.MENU.FANPAGE.NAME),
      icon: <Flag />,
      id: 'fanpage_manager',
      link: types.MENU.FANPAGE.LINK,
    },
    {
      name: t(types.MENU.CUSTOMER.NAME),
      icon: <UserIcon />,
      id: 'customer_manager',
      link: types.MENU.CUSTOMER.LINK,
    },
    {
      name: t(types.MENU.TAG.NAME),
      icon: <Tags />,
      id: 'tag_manager',
      link: types.MENU.TAG.LINK,
    },
    {
      name: t(types.MENU.CAMPAGIGN.NAME),
      icon: <Gauge />,
      id: 'campaign_manager',
      link: types.MENU.CAMPAGIGN.LINK,
    },
    {
      name: t(types.MENU.SCENARIO.NAME),
      icon: <CalendarIcon />,
      id: 'scenario_manager',
      link: types.MENU.SCENARIO.LINK,
    },
  ]

  useEffect(() => {
    const url_string = window.location.href //window.location.href
    const url = new URL(url_string)
    const pathname = url.pathname
    setActiveMenu(pathname)
  }, [])

  const handleMenu = (item) => {
    history.push(item.link)
    // onMenu(item.link);
  }

  const handleClickTutorial = (url) => {
    window.open(url, '_blank')
  }
  const handleClickExtension = (url) => {
    window.open(url, '_blank')
  }

  const handleSubmitOpenExtension = () => {
    window.open(
      types.URL.FACEBOOK + `&token=${localStorage.getItem('token') || null}`,
      '_blank'
    )
  }
  const handleDehazeIcon = () => {
    onDehaze(!dehaze)
  }

  const list = () => (
    <>
      <div className={cn(dehaze ? classes.dehazeList : classes.list)}>
        <div className="d-flex flex-column align-items-center">
          <div className={classes.container}>
            <div className={cn(classes.logo)}>
              <a href="https://bombot.vn/" target="_blank" rel="noreferrer">
                <img src={IMAGES.LOGO} />
              </a>
            </div>
            <div className={classes.dehazeIcon}>
              <span onClick={() => handleDehazeIcon()}>
                <DehazeIcon style={{ color: 'rgb(117,117,117)' }} />
              </span>
            </div>
          </div>
        </div>

        <List>
          {menu.map((item, index) => (
            <ListItem
              className={cn(
                activeMenu === item.link ? classes.active : '',
                'pl-0 pr-2'
              )}
              button
              key={index}
              onClick={() => handleMenu(item)}
            >
              <Flex align="center" id={item.id} className="w-100 px-2">
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText className={classes.text} primary={item.name} />
              </Flex>
            </ListItem>
          ))}
        </List>
        <div className={classes.bottom}>
          <List>
            <ButtonComponent
              className={cn(
                classes.button,
                'btn--rounder',
                'btn--primary--filled'
              )}
              title={t('common.btn.create_campaign')}
              backgroundColor="#05AE34"
              color="white"
              handleClickButton={() => handleSubmitOpenExtension()}
            />
            <ButtonComponent
              backgroundColor="#007bff"
              className={cn(classes.button, 'btn--rounder')}
              title={t('common.btn.user_manual')}
              color="white"
              handleClickButton={() => handleClickTutorial(types.URL.TUTORIAL)}
            />
            <ButtonComponent
              className={cn(classes.button, 'btn--rounder')}
              title={t('common.btn.download_file')}
              backgroundColor="#AE1607"
              color="white"
              handleClickButton={() =>
                handleClickExtension(types.URL.EXTENSION)
              }
            />
          </List>
        </div>
      </div>

      {dehaze && (
        <div className={cn(classes.listDehaze)}>
          <span
            className={cn(classes.dehazeIcon)}
            onClick={() => handleDehazeIcon()}
          >
            <DehazeIcon style={{ color: 'rgb(117,117,117)' }} />
          </span>
          <div className={cn(classes.logoDehaze)}>
            <a href="https://bombot.vn/" target="_blank" rel="noreferrer">
              <img src={IMAGES.FAVICON} />
            </a>
          </div>

          <List>
            {menu.map((item, index) => (
              <ListItem
                className={cn(
                  classes.dehazeLink,
                  activeMenu == item.link ? classes.active : ''
                )}
                button
                key={index}
                onClick={() => handleMenu(item)}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
              </ListItem>
            ))}
          </List>
          <div className={classes.bottom}>
            <List>
              <ListItem
                className={cn(classes.dehazeLink)}
                button
                onClick={() => handleSubmitOpenExtension()}
              >
                <ListItemIcon>{<AddAlertIcon />}</ListItemIcon>
              </ListItem>

              <ListItem
                className={cn(classes.dehazeLink)}
                button
                onClick={() => handleClickTutorial(types.URL.TUTORIAL)}
              >
                <ListItemIcon>{<ForumIcon />}</ListItemIcon>
              </ListItem>

              <ListItem
                className={cn(classes.dehazeLink)}
                button
                onClick={() => handleClickExtension(types.URL.EXTENSION)}
              >
                <ListItemIcon>{<GetAppIcon />}</ListItemIcon>
              </ListItem>
            </List>
          </div>
        </div>
      )}
    </>
  )

  // const listDehaze = () => (

  // );

  return (
    // <div>
    //   {["left", "right", "top", "bottom"].map((anchor) => (
    //     <React.Fragment key={anchor}>
    //       <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button>
    //       {/* <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}> */}
    //       <Drawer anchor={anchor} open={state[anchor]}>
    //         {list(anchor)}
    //       </Drawer>
    //     </React.Fragment>
    //   ))}
    // </div>
    list()
  )
}
