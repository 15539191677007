const baseUrl = `${process.env.REACT_APP_API_URL}`

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  shared: {
    getWhatYouNeed: `${baseUrl}api/v1.0/shared/getWhatYouNeed`, // get
    getDashboard: `${baseUrl}api/v1.0/shared/dashboard`, // get
  },
  dashboard: {
    getMessageStatistic: `${baseUrl}api/v1.0/getStatistic/message`, // get
    getCampaignStatistic: `${baseUrl}api/v1.0/getStatistic/campaign`, // get
    getScenarioStatistic: `${baseUrl}api/v1.0/getStatistic/scenario`, // get
    getCustomerStatistic: `${baseUrl}api/v1.0/getStatistic/customer`, // get
    getGenAIStatistic: `${baseUrl}api/v1.0/genAI/getStatistic`, // get
    getFanpageStatistic: `${baseUrl}api/v1.0/fanPage/reportPage`, // get
    getTagBombotStatistic: `${baseUrl}api/v1.0/bombotTags/getStatistic`, // get
    getTagFacebookStatistic: `${baseUrl}api/v1.0/facebookTags/getStatistic`, // get
  },
  appConfig: {
    api: `${baseUrl}api/v1.0/appConfig`, // get
    addLog: `${baseUrl}api/v1.0/log`, // post
  },
  user: {
    loginSocial: `${baseUrl}api/v1.0/user/login_social`, // get
    user: `${baseUrl}api/v1.0/user`, // put
    login: `${baseUrl}api/v1.0/user/login`, // get
    verifyPhone: `${baseUrl}api/v1.0/user/verify`, // get
    getOTP: `${baseUrl}api/v1.0/user/getOTP`, // get
    changePassword: `${baseUrl}api/v1.0/user/changePassword`, // get
    getInviteCode: `${baseUrl}api/v1.0/user/getInviteCode`, // get
    confirmInviteCode: `${baseUrl}api/v1.0/user/confirmInviteCode`, // get
    forgotPassword: `${baseUrl}api/v1.0/user/resetPassword`, // get
    redeemPoints: `${baseUrl}api/v1.0/user/redeemPoints`, // get, doi diem
    unlink: `${baseUrl}api/v1.0/user/unlink`, // DELETE
  },
  fanpageApi: {
    reportPage: `${baseUrl}api/v1.0/fanPage/reportPage`, // get
    fanPageDetail: `${baseUrl}api/v1.0/fanPageDetail`, // post
  },
  campaignApi: {
    campaign: `${baseUrl}api/v1.0/campaign`, // get
  },
  scenarioApi: {
    scenario: `${baseUrl}api/v1.0/campaign/857636ce-e9f7-48c9-8474-9ae5fa309392/scenario?id=857636ce-e9f7-48c9-8474-9ae5fa309392&page=0&sort=createdAt,asc`, // get
  },
  customerApi: {
    customer: `${baseUrl}api/v1.0/customer`, // get
    create: `${baseUrl}api/v1.0/campaign`, // post
    addCampainByPart: `${baseUrl}api/v1.0/campaign/addCampainByPart`, // post
    addCampaignNoScenario: `${baseUrl}api/v1.0/campaign/addCampaignNoScenario`, // post
    createCustomer: `${baseUrl}api/v1.0/campaignCustomer`, // post
    addMultiCustomersBombotTags: `${baseUrl}api/v1.0/customer/customersBombotTags`, // post
    deleteMultiCustomersBombotTags: `${baseUrl}api/v1.0/customer/customersBombotTags`, // delete
  },
}
