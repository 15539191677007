import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
}

const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    openLoading: (state, action) => {
      state.loading = true
    },
    closeLoading: (state, action) => {
      state.loading = false
    },
  },
})

export const { openLoading, closeLoading } = loadingSlice.actions

export default loadingSlice
