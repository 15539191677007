import React from 'react'
import { Controller } from 'react-hook-form'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import {
  DateRangeDelimiter,
  LocalizationProvider,
  DesktopDateRangePicker,
} from '@material-ui/pickers'
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns'
import Flex from 'components/Flex'
import { useTranslation } from 'react-i18next'
import DateRangeIcon from '@material-ui/icons/DateRange'

// Sử dụng makeStyles để tạo styles
const useStyles = makeStyles((theme) => ({
  inputPicker: {
    width: '110px',
    '& input': {
      fontSize: '12px', // Kích thước chữ trong input
      padding: '8px', // Giảm padding
    },
  },
  delimiter: {
    fontSize: '12px',
    margin: '0 8px',
  },
  adornedEnd: {
    paddingRight: 0, // Customize the right padding
  },
}))

const BaseDatePicker2 = ({
  control,
  name,
  defaultValue,
  rules,
  styles,
  ...rest
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Flex align="center" style={styles}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={({ field }) => {
          return (
            <LocalizationProvider dateAdapter={DateFnsUtils}>
              <DesktopDateRangePicker
                {...field}
                maxDate={new Date()}
                inputFormat="dd/MM/yyyy"
                onChange={(date) => {
                  field.onChange(date)
                }}
                value={field.value || [null, null]}
                {...rest}
                renderInput={(startProps, endProps) => {
                  return (
                    <>
                      <TextField
                        size="small"
                        className={classes.inputPicker}
                        {...startProps}
                        error={null}
                        helperText={null}
                        placeholder="next"
                        label={t('dashboard.from_date')}
                        InputProps={{
                          endAdornment: (
                            <DateRangeIcon
                              style={{
                                color: '#cccc',
                                marginRight: '4px',
                                pointerEvents: 'none',
                              }}
                            />
                          ),
                          classes: {
                            adornedEnd: classes.adornedEnd,
                          },
                        }}
                      />
                      <DateRangeDelimiter className={classes.delimiter}>
                        {t('update.form.to')}
                      </DateRangeDelimiter>
                      <TextField
                        size="small"
                        className={classes.inputPicker}
                        {...endProps}
                        error={null}
                        helperText={null}
                        label={t('dashboard.to_date')}
                        InputProps={{
                          endAdornment: (
                            <DateRangeIcon
                              style={{
                                color: '#cccc',
                                marginRight: '4px',
                                pointerEvents: 'none',
                              }}
                            />
                          ),
                          classes: {
                            adornedEnd: classes.adornedEnd,
                          },
                        }}
                      />
                    </>
                  )
                }}
              />
            </LocalizationProvider>
          )
        }}
      />
    </Flex>
  )
}

export default BaseDatePicker2
