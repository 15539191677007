import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { fade, makeStyles, withStyles } from '@material-ui/core/styles'
import { pathKeys } from '../../constants'
import types from '../../constants/types'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import Button from '@material-ui/core/Button'
import Avatar from '@material-ui/core/Avatar'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import InfoIcon from '@material-ui/icons/Info'
import FormDialog from '../../components/FormDialog'
import cn from 'classnames'
import Flex from 'components/Flex'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { selectShare } from 'views/Home/HomeContainer'
import { handleUpgrade } from 'utils/helper'

const StyledMenu = withStyles({
  paper: {
    marginTop: 10,
    border: 'none !important',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
))

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '& ul': {
      padding: 0,
    },
    '&:hover': {
      backgroundColor: '#05AE34',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
    border: 'none',
  },
}))(MenuItem)

const useStyles = makeStyles((theme) => ({
  header: {
    flexGrow: 1,
    padding: 0,
    position: 'fixed',
    top: 0,
    right: 0,
    zIndex: 2,
    width: 'calc(100% - 250px)',
    border: 'none',
    boxShadow: '0 0 2rem 0 rgba(136,152,170,.15)!important',
    '-webkit-transition': 'all 0.1s ease-out',
  },
  headerDehaze: {
    flexGrow: 1,
    padding: 0,
    position: 'fixed',
    top: 0,
    right: 0,
    border: 'none',
    boxShadow: '0 0 2rem 0 rgba(136,152,170,.15)!important',
    zIndex: 2,
    width: 'calc(100% - 60px)',
    '& $sectionDesktop': {
      marginLeft: 30,
    },
    '-webkit-transition': 'all 0.09s ease-out',
    // "& header": {
    //   boxShadow: "0 0 2rem 0 rgba(136,152,170,.15)!important"
    // }
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  account: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    float: 'right',
  },
  wrapperHeader: {
    display: 'flex',
    border: 'none',
    boxShadow: 'none',
    justifyContent: 'space-between',
  },
  cursor: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  wrapperAvatar: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapVersion: {
    padding: '0px 15px',
    '& h4': {
      marginBottom: 0,
      padding: 0,
      color: '#05AE34',
    },
    '& h6': {
      marginTop: 5,
      fontSize: 14,
      color: 'red',
    },
  },
}))

export default function Header(props) {
  const { getMenu, onMenu, onLogout, user, onDehaze, dehaze } = props
  const classes = useStyles()
  const history = useHistory()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [titleMenu, setTitleMenu] = React.useState('')
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null)
  const [isPopupVersion, setIsPopupVersion] = React.useState(false)
  const [titlePage, setTitlePage] = React.useState('')
  const { t, i18n } = useTranslation()
  const share = useSelector(selectShare)['share']
  const dispatch = useDispatch()

  useEffect(() => {
    const url_string = window.location.href //window.location.href
    const url = new URL(url_string)
    const pathname = url.pathname
    if (pathname) {
      switch (pathname) {
        case types.MENU.HOME.LINK:
          setTitlePage(t(types.MENU.HOME.NAME))
          break
        case types.MENU.CUSTOMER.LINK:
          setTitlePage(t(types.MENU.CUSTOMER.NAME))
          break
        case types.MENU.FANPAGE.LINK:
          setTitlePage(t(types.MENU.FANPAGE.NAME))
          break
        case types.MENU.CAMPAGIGN.LINK:
          setTitlePage(t(types.MENU.CAMPAGIGN.NAME))
          break
        case types.MENU.TAG.LINK:
          setTitlePage(t(types.MENU.TAG.NAME))
          break
        case types.MENU.SETTING_ACCOUNT.LINK:
          setTitlePage(t(types.MENU.SETTING_ACCOUNT.NAME))
          break
        case types.MENU.SCENARIO.LINK:
          setTitlePage(t(types.MENU.SCENARIO.NAME))
          break

        default:
          break
      }
    }
  }, [i18n.language])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    setTitleMenu(getMenu)
  }, [getMenu])

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
    handleMobileMenuClose()
  }

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget)
  }

  const menuId = 'primary-search-account-menu'

  const mobileMenuId = 'primary-search-account-menu-mobile'
  const handleSettingAccount = () => {
    history.push(pathKeys.SETTING_ACCOUNT)
    onMenu('Quản lý tài khoản')
  }

  const handleLogOut = () => {
    localStorage.removeItem('alertGiftBox')
    onLogout()
  }

  const handleClickPay = () => {
    handleUpgrade({ i18n, user, dispatch })
  }
  const handleCloseVersion = () => {
    setIsPopupVersion(false)
  }
  const handleVersion = () => {
    setIsPopupVersion(true)
  }

  return (
    <div className={cn(dehaze ? classes.headerDehaze : classes.header)}>
      <AppBar position="static" style={{ boxShadow: 'none', border: 'none' }}>
        <Toolbar
          style={{
            background: '#fff',
            color: '#333',
            border: 'none',
            boxShadow: 'none',
          }}
          className={classes.wrapperHeader}
        >
          <div className={classes.sectionDesktop}>
            <h3>{titlePage}</h3>
          </div>
          <div className={classes.account}>
            <Button
              style={{
                margin: 20,
                background: '#FF8000',
                fontSize: 14,
                fontWeight: 'bold',
                color: 'white',
              }}
              className={cn('btn--rounder')}
              variant="contained"
              id="upgrade"
              onClick={() => handleClickPay()}
            >
              {t('common.btn.upgrade')}
            </Button>
            <Flex
              align="center"
              className={classes.wrapperAvatar}
              onClick={handleClick}
            >
              {user.avatar ? (
                <Avatar alt={user.name} src={user.avatar} />
              ) : (
                <Avatar alt={user.name} />
              )}
              {/* <p className={classes.cursor} style={{ marginLeft: 15 }} onClick={() => handleSettingAccount()}>{user && user.name}</p> */}
              <Flex
                align="center"
                className={cn(classes.cursor, 'm-0', 'ml-2', 'fw-500')}
              >
                <p> {user && user.name}</p>
                <span className={classes.cursor}>
                  <ArrowDropDownIcon />
                </span>
              </Flex>
            </Flex>
            <StyledMenu
              id="customized-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <StyledMenuItem onClick={() => handleSettingAccount()}>
                <ListItemIcon>
                  <AccountCircleIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={t('menu.account')} />
              </StyledMenuItem>
              <StyledMenuItem onClick={() => handleVersion()}>
                <ListItemIcon>
                  <InfoIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={t('menu.version')} />
              </StyledMenuItem>
              <StyledMenuItem onClick={() => handleLogOut()}>
                <ListItemIcon>
                  <ExitToAppIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={t('menu.logout')} />
              </StyledMenuItem>
            </StyledMenu>

            <div className={classes.grow} />
          </div>
        </Toolbar>
      </AppBar>
      <FormDialog
        isOpen={isPopupVersion}
        handleClose={() => handleCloseVersion()}
      >
        <div className={cn(classes.wrapVersion, 'p-4')}>
          <h4 className={classes.titleVersion}>{t('version.title')}</h4>
          <div
            dangerouslySetInnerHTML={{
              __html: share && share.version && share.version.content,
            }}
          />
        </div>
      </FormDialog>
    </div>
  )
}
