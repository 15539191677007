import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

// Styles
const useStyles = makeStyles((theme) => ({
  dialog: {
    borderRadius: '8px',
    padding: theme.spacing(2),
  },
  title: {
    fontWeight: 'bold',
    fontSize: '20px',
    color: theme.palette.primary.main,
  },
  message: {
    fontSize: '16px',
    color: theme.palette.text.primary,
    marginTop: theme.spacing(1),
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
  },
  button: {
    padding: theme.spacing(1, 2),
    textTransform: 'capitalize',
  },
}))

const ConfirmModal2 = ({
  open,
  onClose,
  onConfirm,
  title = 'Confirmation',
  message = 'Are you sure?',
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  confirmColor = 'primary',
  cancelColor = 'default',
}) => {
  const classes = useStyles()

  const { t } = useTranslation()

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="confirm-dialog-title"
      classes={{ paper: classes.dialog }}
    >
      {/* Tiêu đề */}
      <DialogTitle id="confirm-dialog-title" className={classes.title}>
        {title}
      </DialogTitle>

      {/* Nội dung */}
      <DialogContent>
        <Typography className={classes.message}>{message}</Typography>
      </DialogContent>

      {/* Hành động */}
      <DialogActions className={classes.actions}>
        <Button
          onClick={onClose}
          color={cancelColor}
          className={classes.button}
        >
          {t('common.btn.cancel')}
        </Button>
        <Button
          onClick={onConfirm}
          color={confirmColor}
          variant="contained"
          className={classes.button}
        >
          {t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ConfirmModal2.propTypes = {
  open: PropTypes.bool.isRequired, // Modal có hiển thị không
  onClose: PropTypes.func.isRequired, // Hàm đóng modal
  onConfirm: PropTypes.func.isRequired, // Hàm xác nhận hành động
  title: PropTypes.string, // Tiêu đề của modal
  message: PropTypes.string, // Nội dung của modal
  confirmText: PropTypes.string, // Text trên nút xác nhận
  cancelText: PropTypes.string, // Text trên nút hủy
  confirmColor: PropTypes.oneOf(['default', 'primary', 'secondary']), // Màu nút xác nhận
  cancelColor: PropTypes.oneOf(['default', 'primary', 'secondary']), // Màu nút hủy
}

export default ConfirmModal2
