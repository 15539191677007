import {
  Card,
  CardContent,
  IconButton,
  makeStyles,
  Tooltip
} from '@material-ui/core'
import moment from 'moment'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Flex from '../../../../components/Flex'
import { pathKeys } from '../../../../constants'
import styles from '../../styles.module.scss'
import { DashboardEmpty } from '../Chart'

const useStyles = makeStyles((theme) => ({
  copyButton: {
    padding: 4,
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)"
    }
  },
  card: {
    borderRadius: "12px" 
  }
}));

const AccountInfo = ({ data }) => {
  const { t } = useTranslation()

  const [copied, setCopied] = useState(false)

  const classes = useStyles()

  const handleCopy = useCallback((id) => {
    navigator.clipboard.writeText(id).then(() => {
      setCopied(true)
      setTimeout(() => setCopied(false), 2000)
    })
  }, [])

  return (
    <div className={styles.account__info}>
      <Card className={`${classes.card}`}>
        <CardContent className="pt-4 pb-4">
          <h3
            style={{
              color: "#05AE34", 
              fontSize: "16px", 
              fontWeight: 600, 
              marginBottom: "16px"
            }}
          >
            {t("overall.account_info.title")}
          </h3>
          {!data?.info?.id ? (
            <DashboardEmpty />
          ) : (
            <>
              <table className={styles.info__table}>
                <tr>
                  <th>{t("overall.account_info.account_id")}:</th>
                  <td className="fw-500 text-primary">
                    <Flex align="center" justify="space-between">
                      <span>{data?.info?.id}</span>

                      <Tooltip title={copied ? t("copied") : t("copy")}>
                        <IconButton
                          className={classes.copyButton}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleCopy(data?.info?.id);
                          }}
                          size="small"
                          style={{
                            marginLeft: "12px"
                          }}
                        >
                          <img
                            src="/image/Clipboard-alt.svg"
                            alt="Copy"
                            style={{ width: "20px", height: "20px" }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Flex>
                  </td>
                </tr>

                {data?.info?.type &&
                  (data?.info?.type === "bombot-eternity" ||
                    data?.info?.type === "bombot-infinity" ||
                    data?.info?.type === "bombot-v-nh-vi-n" ||
                    data?.info?.type === "bombot-v-h-n") && (
                    <tr>
                      <th>{t("overall.account_info.type")}:</th>
                      <td className="fw-500 text-primary">
                        {t("overall.account_info." + data?.info?.type)}
                      </td>
                    </tr>
                  )}
                <tr>
                  <th>{t("overall.account_info.giftPoint")}:</th>
                  <td>{data?.info?.giftPoint}</td>
                </tr>
                <tr>
                  <th>{t("overall.account_info.expire_date")}:</th>
                  <td className="text-danger">
                    {data?.info?.expiredAt
                      ? data?.info?.expiredAt > "2100-12-24T05:46:34.304+00:00"
                        ? t("overall.account_info.lifetime")
                        : moment(data.info.expiredAt).format("DD/MM/YYYY HH:mm")
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <th>{t("overall.account_info.linked_account")}:</th>
                  <td>{data?.info?.numRef}</td>
                </tr>
                <tr>
                  <th>{t("overall.account_info.amount_customer")}:</th>
                  <td>{data?.customer?.totalCus}</td>
                </tr>
                <tr>
                  <th>{t("overall.account_info.amount_fanpage")}:</th>
                  <td>{data?.fanpage?.totalPage}</td>
                </tr>
              </table>
              <Flex justify="center">
                <Link
                  className={styles.box__action}
                  to={`${pathKeys.SETTING_ACCOUNT}`}
                >
                  {t("account_management.label")}
                </Link>
              </Flex>
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
}

export default AccountInfo
