import React, { useEffect, useMemo, useState } from "react";
import homeApi from "../../../../api/homeApi";
import { Card } from "@material-ui/core";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

const FanpageChart = ({ style, ...res }) => {
  const { t } = useTranslation();
  const [data, setData] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const res = await homeApi.getFanpageStatistic({
          page: 0,
          size: 15,
          sort: "totalCus,desc"
        });
        setData(res);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const chartCustomerPerFanpages = useMemo(() => {
    return {
      labels: data?.content?.map((item) => item.name) || [],
      datasets: [
        {
          label: t("dashboard.customer_count"),
          data: data?.content?.map((item) => item.totalCus) || [], 
          backgroundColor: "#a8d5ba",
          borderColor: "#4cae50",
          borderWidth: 1
        }
      ]
    };
  }, [data, t]);

  const barOptions = {
    indexAxis: "y", // This makes the bar chart horizontal
    responsive: true,
    plugins: {
      legend: {
        position: "top"
      },
      title: {
        display: true
      }
    },
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return Math.floor(value); // Round down to the nearest whole number
          }
        }
      }
    }
  };

  return (
    <Card style={style} {...res}>
      <h3 style={{ margin: 0 }}>{t("dashboard.customer_fanpage")}</h3>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%"
        }}
      >
        <Bar data={chartCustomerPerFanpages} options={barOptions} />
      </div>
    </Card>
  );
};

export default FanpageChart;
