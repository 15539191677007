import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import { Dialog } from "@material-ui/core";
import cn from "classnames";
import Flex from "components/Flex";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import {
  getIsGuideByKey,
  GUIDE_TYPE_ENUM,
  setIsGuideByKey
} from "utils/helper";
import { useSelector } from "react-redux";
import { selectGuideListByKey } from "stores/config/config.selector";
import YouTube from "react-youtube";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "8px 24px",
    backgroundColor: "#ffffff",
    borderRadius: "12px"
  },
  button: {
    marginRight: theme.spacing(2),
    borderRadius: "8px",
    padding: "8px 16px"
  },
  instructions: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontSize: "16px",
    backgroundColor: "#ffffff"
  },
  step: {
    width: "100%",
    backgroundColor: "#ffffff"
  },
  stepper: {
    width: "100%",
    padding: "0",
    backgroundColor: "transparent",
    padding: "16px"
  },
  content: {
    textAlign: "center",
    marginBottom: "16px"
  }
}));

const StepContent = ({ step, data }) => {
  if (!data?.length) return null;
  return (
    <Flex justify="center" align="center" column>
      <div className={styles.content}>
        <YouTube
          videoId={data[step]?.link_video.replace(
            "https://www.youtube.com/embed/",
            ""
          )}
        />
      </div>
      <h1 className="fz-20 fw-700 m-4" style={{ color: "#333" }}>
        {data[step]?.title}
      </h1>
    </Flex>
  );
};

export default function Introduce({ handleClose }) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const contentRef = useRef();
  const { t } = useTranslation();

  const introduceData = useSelector(
    selectGuideListByKey(GUIDE_TYPE_ENUM.video_introduce)
  );
  const [tourConfig, setTourConfig] = useState([]);

  useEffect(() => {
    if (
      getIsGuideByKey(GUIDE_TYPE_ENUM.video_introduce) &&
      introduceData?.length
    ) {
      setTourConfig(introduceData);
    }
  }, [introduceData]);

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const closeModal = () => {
    setTourConfig([]);
    setIsGuideByKey(GUIDE_TYPE_ENUM.video_introduce);
    handleClose();
  };

  const handleNext = () => {
    if (activeStep === tourConfig.length - 1) {
      closeModal();
    }
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Dialog
      maxWidth="xl"
      open={tourConfig?.length > 0}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          borderRadius: "12px",
          padding: "24px",
          backgroundColor: "#ffffff"
        }
      }}
    >
      <div className={classes.root}>
        <Flex justify="center" style={{ width: "100%", boxShadow: "none" }}>
          <div className={styles.step} style={{ width: "100%" }}>
            <Stepper activeStep={activeStep} className={classes.stepper}>
              {tourConfig.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={index} {...stepProps}>
                    <StepLabel {...labelProps}></StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </div>
        </Flex>
        <div>
          <StepContent step={activeStep} data={tourConfig} ref={contentRef} />
          <Flex justify="flex-end" align="center" className="mt-4">
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              variant="contained"
              size="small"
              className={cn(classes.button, "btn--rounder", "fw-500")}
              color="default"
            >
              {t("update.back")}
            </Button>

            <Button
              variant="contained"
              size="small"
              onClick={handleNext}
              className={cn(
                classes.button,
                "ml-2",
                "btn--rounder",
                "btn--primary--filled",
                "fw-500",
                "text-uppercase"
              )}
              color="primary"
            >
              {activeStep === tourConfig.length - 1
                ? t("common.btn.close")
                : t("update.next")}
            </Button>
          </Flex>
        </div>
      </div>
    </Dialog>
  );
}
