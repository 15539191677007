import { Card } from '@material-ui/core'
import { useEffect, useMemo, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'
import homeApi from 'api/homeApi'

const BombotTagChart = ({ style, ...res }) => {
  const { t } = useTranslation()
  const [data, setData] = useState([])
  const [selectedDate, setSelectedDate] = useState(null)

  useEffect(() => {
    ;(async () => {
      try {
        const res = await homeApi.getTagBombotStatistic({
          size: 15,
          pageId: '',
        })
        setData(res)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [selectedDate])

  const chartCustomerPerFanpages = useMemo(() => {
    return {
      labels: data?.map((item) => item.tagName),
      datasets: [
        {
          label: t("dashboard.customer_count"),
          data: data?.map((item) => item.totalCus) || [],
          backgroundColor: "#a8d5ba",
          borderColor: "#4cae50",
          borderWidth: 1
        }
      ]
    };
  }, [data])

  const barOptions = {
    indexAxis: 'y',
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
      },
    },
    scales: {
      x: {
        beginAtZero: true,
      },
    },
  }

  return (
    <Card style={style} {...res}>
      <h3
        style={{
          margin: 0,
        }}
      >
        {t('customer_management.list.bombot_tag')}
      </h3>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Bar data={chartCustomerPerFanpages} options={barOptions} />
      </div>
    </Card>
  )
}

export default BombotTagChart
