import { useCallback, useEffect, useRef, useState } from "react";
import scenarioApi from "../../../api/scenarioApi";

import {
  IconButton,
  InputAdornment,
  makeStyles,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ClearIcon from "@material-ui/icons/Clear";

import { Pagination } from "@material-ui/lab";
import CampaignStatusLabel, { campaignStatusDir } from "./CampaignStatusLabel";
import CoppyIcon from "./CoppyIcon";

import { CheckOutlined, SortRounded } from "@material-ui/icons";
import cn from "classnames";
import { ChevronDown, FilterIcon, SearchIcon } from "lucide-react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import ButtonComponent from "../../../components/Button";
import Flex from "../../../components/Flex";
import LoadingWrapper from "../../../components/LoadingWrapper";
import types from "../../../constants/types";
import TimeLabel from "./TimeLabel";

const defaultSizeGetScenario = types.PAGINATION.SIZE;

const useStyles = makeStyles((theme) => ({
  witdhOrd: {
    minWidth: "140px",
    padding: "0 8px !important"
  },

  witdhId: {
    minWidth: "170px",
    padding: "0 8px !important"
  },

  widthName: {
    minWidth: "160px",
    padding: "0 8px !important"
  },

  widthCreator: {
    minWidth: "160px",
    padding: "0 8px !important"
  },

  widthDate: {
    minWidth: "160px",
    padding: "0 8px !important"
  },

  widthStatisticBtn: {
    minWidth: "170px",
    padding: "0 8px !important"
  },

  widthStatistic: {
    minWidth: "180px",
    padding: "0 8px !important"
  },

  widthActions: {
    minWidth: "120px",
    padding: "0 8px !important"
  },

  widthStatus: {
    minWidth: "130px",
    padding: "0 8px !important"
  },

  p0: {
    padding: "0",
    margin: "0"
  },

  heightHeader: {
    height: "52px"
  },

  f600: {
    fontWeight: 600
  },

  actions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",
    padding: "10px 26px 16px 0"
  },

  actionBtnRemove: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#ff4d4f", // Red color for delete button
    fontWeight: 600,
    padding: "6px 12px",
    borderRadius: theme.shape.borderRadius,
    transition: "all 0.3s ease", // Transition for smooth effect
    "&:hover": {
      backgroundColor: "rgba(255, 77, 79, 0.1)" // Light red background on hover
    }
  },

  actionBtnDetail: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "rgb(0, 123, 255)", // Red color for delete button
    fontWeight: 600,
    padding: "6px 10px",
    borderRadius: theme.shape.borderRadius,
    transition: "all 0.3s ease", // Transition for smooth effect
    "&:hover": {
      backgroundColor: "rgba(33, 150, 243, 0.2)" // Light red background on hover
    }
  },

  actionSend: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#52c41a", // Màu xanh lá cho nút gửi tin nhắn
    fontWeight: 600,
    padding: "8px 12px",
    borderRadius: theme.shape.borderRadius,
    transition: "all 0.3s ease", // Hiệu ứng chuyển mượt
    "&:hover": {
      backgroundColor: "rgba(82, 196, 26, 0.1)" // Nền xanh lá nhạt khi hover
    }
  },

  actionAdd: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#636ae8", // Màu xanh lá cho nút gửi tin nhắn
    fontWeight: 600,
    padding: theme.spacing(1, 2),
    borderRadius: theme.shape.borderRadius,
    transition: "all 0.3s ease", // Hiệu ứng chuyển mượt
    "&:hover": {
      backgroundColor: "rgba(99, 106, 232, 0.1)" // Nền xanh lá nhạt khi hover

      // backgroundColor: 'rgba(82, 196, 26, 0.1)', // Nền xanh lá nhạt khi hover
    }
  },

  actionBtnSearch: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#007bff", // Blue color similar to "Bombot Tags" text
    fontWeight: 600,
    padding: theme.spacing(1, 2),
    borderRadius: theme.shape.borderRadius,
    transition: "all 0.3s ease", // Transition for smooth effect
    backgroundColor: "rgb(230, 243, 255, 0.5)",
    "&:hover": {
      backgroundColor: "rgb(230, 243, 255, 0.8)" // Light blue background color
    },
    border: "1px solid #a3c1dd"
  },

  actionIcon: {
    fontSize: "14px"
  },
  actionTitle: {
    margin: 0,
    fontSize: "10px !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },

  rowItem: {
    cursor: "pointer"
  },
  clickableRow: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)"
    }
  }
}));

const ScenarioList = ({ campaignId, isShowFilter, isPagination, isSearch }) => {
  const sortModalRef = useRef(null);
  const filterModalRef = useRef(null);
  const filterPopupRef = useRef(null);

  function useOutsideAlerter(ref, ...handleFuncs) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          handleFuncs.forEach((func) => func());
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, handleFuncs]);
  }

  useOutsideAlerter(
    filterModalRef,
    () => setOpenFilterModal(false),
    () => setIsOpen(false)
  );
  useOutsideAlerter(sortModalRef, () => setOpenSortModal(false));
  useOutsideAlerter(filterPopupRef, () => setIsOpen(false));

  const classes = useStyles();
  const { t } = useTranslation();

  const [scenarioList, setScenarioList] = useState(null);
  const [isCopiedId, setIsCopiedId] = useState(false);
  const [page, setPage] = useState(0);
  const [campaignSelected, setCampaignSelected] = useState(campaignId);

  const [sortNameOrder, setSortNameOrder] = useState("");
  const [tempSortNameOrder, setTempSortNameOrder] = useState("");

  const [sortDateRangeOrder, setSortDateRangeOrder] =
    useState("createdAt,desc");
  const [tempSortDateRangeOrder, setTempSortDateRangeOrder] =
    useState("createdAt,desc");

  const [isOpen, setIsOpen] = useState(false);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [tempStartDate, setTempStartDate] = useState("");
  const [tempEndDate, setTempEndDate] = useState("");

  const [search, setSearch] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(types.PAGINATION.SIZE);
  const [isLoadingList, setIsLoadingList] = useState(false);

  const [openSortModal, setOpenSortModal] = useState(false);
  const [openFilterModal, setOpenFilterModal] = useState(false);

  const dataStatus = [
    {
      id: 0,
      name: "pending",
      value: t("campaign_management.scenario.status.pending")
    },
    {
      id: 1,
      name: "running",
      value: t("campaign_management.scenario.status.running")
    },
    {
      id: 2,
      name: "done",
      value: t("campaign_management.scenario.status.done")
    },
    {
      id: 3,
      name: "cancel",
      value: t("campaign_management.scenario.status.cancel")
    }
  ];

  const [selectedStatus, setSelectedStatus] = useState(
    dataStatus.map((s) => s.name)
  );
  const [tempSelectedStatus, setTempSelectedStatus] = useState(
    dataStatus.map((s) => s.name)
  );

  // useEffect(() => {
  //   (async () => {
  //     const params = createParams(
  //       selectedStatus,
  //       startDate,
  //       endDate,
  //       sortNameOrder,
  //       sortDateRangeOrder,
  //       search
  //     );
  //     const res = await getScenario(params);
  //   })();
  // }, [page]);

  useEffect(() => {
    handleSubmitApply(search);
  }, []);

  const handleSortName = (order) => {
    setSortNameOrder(order);
  };

  const handleSortDateRange = (order) => {
    setSortDateRangeOrder(order);
  };

  useEffect(() => {
    if (openSortModal) {
      setSortNameOrder(tempSortNameOrder);
      setSortDateRangeOrder(tempSortDateRangeOrder);
    }
  }, [openSortModal]);

  useEffect(() => {
    if (openFilterModal) {
      setStartDate(tempStartDate);
      setEndDate(tempEndDate);
      setSelectedStatus(tempSelectedStatus);
    }
  }, [openFilterModal]);

  const getScenario = async (params) => {
    try {
      setIsLoadingList(true);
      const res = await scenarioApi.get(params);
      setScenarioList(res);
      return res;
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingList(false);
    }
  };

  useEffect(() => {
    setCampaignSelected(campaignId);
  }, [campaignId]);

  const onViewScenario = useCallback((campaignId, scenarioId, pageId) => {
    window.open(
      `${types.URL.FACEBOOK}&token=${
        localStorage.getItem("token") || null
      }&campaign=${campaignId}&pageId=${pageId}&scenario=${scenarioId}`,
      "_blank"
    );
  }, []);

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
  };

  const handleResetDateRange = () => {
    setStartDate("");
    setEndDate("");
  };

  const handleResetStatus = () => {
    setSelectedStatus(dataStatus.map((status) => status.name));
  };

  const handleResetAllFilter = () => {
    setStartDate("");
    setEndDate("");
    setSelectedStatus(dataStatus.map((s) => s.name));
    handleSubmitApply(
      search,
      0,
      rowsPerPage,
      "",
      "",
      dataStatus.map((s) => s.name),
      sortNameOrder,
      sortDateRangeOrder
    );
    setTempStartDate("");
    setTempEndDate("");
    setTempSelectedStatus(dataStatus.map((s) => s.name));
  };

  const handleSubmitApply = (
    searchVal = search,
    pageVal = page,
    sizeVal = rowsPerPage,
    startdate = startDate,
    enddate = endDate,
    selectedstatus = selectedStatus,
    sortnameorder = sortNameOrder,
    sortdaterangeorder = sortDateRangeOrder
  ) => {
    setTempSortNameOrder(sortNameOrder);
    setTempSortDateRangeOrder(sortDateRangeOrder);
    setTempStartDate(startDate);
    setTempEndDate(endDate);
    setTempSelectedStatus(selectedStatus);
    const params = createParams(
      selectedstatus,
      startdate,
      enddate,
      sortnameorder,
      sortdaterangeorder,
      searchVal,
      pageVal,
      sizeVal
    );
    getScenario(params);
    setOpenSortModal(false);
    setOpenFilterModal(false);
  };

  const createParams = (
    selectedStatus,
    startDate,
    endDate,
    sortNameOrder,
    sortDateRangeOrder,
    search,
    page,
    size
  ) => {
    let searchParams = "";

    if (
      selectedStatus.length > 0 &&
      selectedStatus.length < dataStatus.length
    ) {
      searchParams += `scenario.status=in=${selectedStatus.join(",")};`;
    }

    if (startDate && endDate) {
      searchParams += `scenario.createdAt=bw=${startDate},${endDate};`;
    }
    if (search && typeof search === "string" && search.trim() !== "") {
      searchParams += `scenario.name=contain=${search};`;
    }

    console.log("searchParams ==>", searchParams);

    const sortOptions = [];
    if (sortDateRangeOrder) {
      sortOptions.push(sortDateRangeOrder);
    }
    if (sortNameOrder) {
      sortOptions.push(sortNameOrder);
    }

    const sort =
      sortOptions.length > 0 ? sortOptions.join(";") : "createdAt,desc";

    const payload = {
      page: page,
      search: searchParams,
      size: size,
      sort: sort
    };

    return payload;
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmitApply();
    }
  };

  const handleResetAllSort = () => {
    setSortNameOrder("");
    setSortDateRangeOrder("createdAt,desc");
    handleSubmitApply(
      search,
      0,
      rowsPerPage,
      startDate,
      endDate,
      selectedStatus,
      "",
      "createdAt,desc"
    );
    setSortNameOrder("");
    setTempSortNameOrder("");
    setSortDateRangeOrder("createdAt,desc");
    setTempSortDateRangeOrder("createdAt,desc");
  };

  const handleToggleSortModal = () => {
    setOpenSortModal(!openSortModal);
  };

  const handleToggleFilterModal = () => {
    setOpenFilterModal(!openFilterModal);
  };

  const handleSelect = (status) => {
    if (status === "all") {
      if (selectedStatus.length === dataStatus.length) {
        return;
      } else {
        setSelectedStatus(dataStatus.map((s) => s.name));
      }
    } else {
      setSelectedStatus((prev) => {
        if (prev.includes(status)) {
          const newSelected = prev.filter((s) => s !== status);
          if (newSelected.length === 0) {
            return [];
          }
          return newSelected;
        } else {
          const newSelected = [...prev, status];
          if (newSelected.length === dataStatus.length) {
            return newSelected;
          }
          return newSelected;
        }
      });
    }
  };

  const handleClickFilter = () => {
    handleSubmitApply();
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    handleSubmitApply(search, 0, newRowsPerPage);
  };

  const handleChange = (event, value) => {
    setPage(value - 1);
    handleSubmitApply(search, value - 1);
  };

  const handleSubmitSearch = (search) => {
    handleSubmitApply();
  };

  return (
    <div
      id="scenario_list"
      style={{
        height: "100%",
        padding: "15px 0px 15px 0px"
      }}
    >
      <Flex
        align="center"
        auto
        style={{ marginBottom: "12px" }}
        id="search-scenario"
      >
        <TextField
          variant="outlined"
          size="small"
          value={search}
          style={{ backgroundColor: "#ffffff" }}
          onChange={handleSearch}
          className={classes.textField}
          placeholder={t("campaign_management.scenario.search")}
          fullWidth
          onKeyDown={onKeyDown}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {search && (
                  <IconButton
                    onClick={() => {
                      setSearch("");
                      handleSubmitApply("");
                    }}
                    size="small"
                  >
                    <ClearIcon />
                  </IconButton>
                )}
              </InputAdornment>
            )
          }}
        />
        <ButtonComponent
          id="search"
          className={cn(
            classes.buttonSearch,
            "btn--rounder",
            "btn--primary--filled",
            "ml-2"
          )}
          title={
            <span
              style={{
                whiteSpace: "nowrap",
                display: "inline-block"
              }}
            >
              {t("common.btn.search")}
            </span>
          }
          backgroundColor="#05AE34"
          color="white"
          handleClickButton={() => handleSubmitSearch(search)}
          style={{
            minWidth: "fit-content",
            padding: "6px 16px"
          }}
        />
      </Flex>

      {/* open modal sort & filter */}
      {/* {isShowFilter && ( */}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "14px",
          gap: "7px"
        }}
      >
        <div
          style={{
            position: "relative",
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            zIndex: "3"
          }}
        >
          <ButtonComponent
            id="sort-scenario"
            className={cn(
              "MuiButtonBase-root MuiButton-root MuiButton-contained btn--rounder btn--primary",
              "text-uppercase MuiButton-containedSizeSmall MuiButton-sizeSmall"
            )}
            title={
              <span
                className="MuiButton-label"
                style={{ display: "flex", alignItems: "center" }}
              >
                <SortRounded style={{ marginRight: "4px" }} />
                <p className="ml-1"> {t("common.btn.sort")}</p>
              </span>
            }
            backgroundColor="#05AE34"
            color="white"
            handleClickButton={handleToggleSortModal}
            style={{
              minWidth: "fit-content",
              padding: "6px 16px",
              background: "#05AE34"
            }}
          />

          {openSortModal && (
            <div
              ref={sortModalRef}
              className="absolute left-0 w-full bg-white py-3"
              style={{
                zIndex: 3,
                borderRadius: "8px",
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: "#C1C1C1FF",
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
                top: "100%",
                marginTop: "4px",
                position: "absolute",
                maxWidth: "290px",
                width: "100%"
              }}
            >
              <p className="px-3 mb-2">{t("campaign_management.name")}</p>

              {/* name: a-z */}
              <div className="px-2 mb-1">
                <Button
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#E1E1E1FF")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor =
                      sortNameOrder === "name,asc" ? "#d8fcd2" : "#fff")
                  }
                  onClick={() => handleSortName("name,asc")}
                  className=""
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    width: "100%",
                    color: "#333",
                    backgroundColor:
                      sortNameOrder === "name,asc" ? "#d8fcd2" : "#fff"
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      width: "16px",
                      height: "16px",
                      borderRadius: "50%",
                      border:
                        sortNameOrder === "name,asc"
                          ? "1px solid #05AE34"
                          : "1px solid #C1C1C1FF",
                      marginRight: "8px",
                      backgroundColor:
                        sortNameOrder === "name,asc" ? "#05AE34" : "#fff",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        width: "8px",
                        height: "8px",
                        borderRadius: "50%",
                        backgroundColor:
                          sortNameOrder === "name,asc" ? "#fff" : "transparent", // Đổi màu thành "transparent" khi không được chọn
                        transition: "background-color 0.3s ease" // Thêm hiệu ứng chuyển màu
                      }}
                    />
                  </div>

                  <div>A - Z</div>
                </Button>
              </div>

              {/* name: z-a */}
              <div className="px-2">
                <Button
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#E1E1E1FF")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor =
                      sortNameOrder === "name,desc" ? "#d8fcd2" : "#fff")
                  }
                  onClick={() => handleSortName("name,desc")}
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    width: "100%",
                    color: "#333",
                    backgroundColor:
                      sortNameOrder === "name,desc" ? "#d8fcd2" : "#fff"
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      width: "16px",
                      height: "16px",
                      borderRadius: "50%",
                      border:
                        sortNameOrder === "name,desc"
                          ? "1px solid #05AE34"
                          : "1px solid #C1C1C1FF",
                      marginRight: "8px",
                      backgroundColor:
                        sortNameOrder === "name,desc" ? "#05AE34" : "#fff",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        width: "8px",
                        height: "8px",
                        borderRadius: "50%",
                        backgroundColor:
                          sortNameOrder === "name,desc"
                            ? "#fff"
                            : "transparent",
                        transition: "background-color 0.3s ease"
                      }}
                    />
                  </div>

                  <div>Z - A</div>
                </Button>
              </div>

              <div
                style={{
                  width: "100%",
                  height: "1px",
                  marginTop: "12px",
                  marginBottom: "12px",
                  backgroundColor: "#C1C1C1FF"
                }}
              />

              <p className="px-3 mb-2">{t("campaign_management.time")}</p>

              {/* newest-oldest */}
              <div className="px-2 mb-1">
                <Button
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#E1E1E1FF")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor =
                      sortDateRangeOrder === "createdAt,desc"
                        ? "#d8fcd2"
                        : "#fff")
                  }
                  onClick={() => handleSortDateRange("createdAt,desc")}
                  className=""
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    width: "100%",
                    color: "#333",
                    backgroundColor:
                      sortDateRangeOrder === "createdAt,desc"
                        ? "#d8fcd2"
                        : "#fff"
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      width: "16px",
                      height: "16px",
                      borderRadius: "50%",
                      border:
                        sortDateRangeOrder === "createdAt,desc"
                          ? "1px solid #05AE34"
                          : "1px solid #C1C1C1FF",
                      marginRight: "8px",
                      backgroundColor:
                        sortDateRangeOrder === "createdAt,desc"
                          ? "#05AE34"
                          : "#fff",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        width: "8px",
                        height: "8px",
                        borderRadius: "50%",
                        backgroundColor:
                          sortDateRangeOrder === "createdAt,desc"
                            ? "#fff"
                            : "transparent",
                        transition: "background-color 0.3s ease"
                      }}
                    />
                  </div>
                  <div>
                    <span style={{ textTransform: "none" }}>
                      {t("campaign_management.newest_oldest")}
                    </span>
                  </div>
                </Button>
              </div>

              {/* oldest-newest */}
              <div className="px-2 mb-1">
                <Button
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#E1E1E1FF")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor =
                      sortDateRangeOrder === "createdAt,asc"
                        ? "#d8fcd2"
                        : "#fff")
                  }
                  onClick={() => handleSortDateRange("createdAt,asc")}
                  className=""
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    width: "100%",
                    color: "#333",
                    backgroundColor:
                      sortDateRangeOrder === "createdAt,asc"
                        ? "#d8fcd2"
                        : "#fff"
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      width: "16px",
                      height: "16px",
                      borderRadius: "50%",
                      border:
                        sortDateRangeOrder === "createdAt,asc"
                          ? "1px solid #05AE34"
                          : "1px solid #C1C1C1FF",
                      marginRight: "8px",
                      backgroundColor:
                        sortDateRangeOrder === "createdAt,asc"
                          ? "#05AE34"
                          : "#fff",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        width: "8px",
                        height: "8px",
                        borderRadius: "50%",
                        backgroundColor:
                          sortDateRangeOrder === "createdAt,asc"
                            ? "#fff"
                            : "transparent",
                        transition: "background-color 0.3s ease"
                      }}
                    />
                  </div>
                  <div>
                    {" "}
                    <span style={{ textTransform: "none" }}>
                      {t("campaign_management.oldest_newest")}
                    </span>
                  </div>
                </Button>
              </div>

              <div
                style={{
                  width: "100%",
                  height: "1px",
                  marginTop: "12px",
                  marginBottom: "12px",
                  backgroundColor: "#C1C1C1FF"
                }}
              />

              {/* reset va apply ben sort */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "12px",
                  marginTop: "8px",
                  paddingLeft: "8px",
                  paddingRight: "8px"
                }}
              >
                <ButtonComponent
                  id="reset"
                  handleClickButton={handleResetAllSort}
                  className={cn("btn--grayMain")}
                  title={
                    <span className="MuiButton-label">
                      {t("common.btn.reset")}
                    </span>
                  }
                  backgroundColor="#05AE34"
                  color="white"
                  style={{
                    minWidth: "fit-content",
                    padding: "6px 16px",
                    background: "#05ae34"
                  }}
                />
                <ButtonComponent
                  id="apply"
                  handleClickButton={handleSubmitApply}
                  className={cn(
                    classes.buttonFilter,
                    "btn--rounder",
                    "btn--primary--filled"
                  )}
                  title={
                    <span
                      style={{
                        whiteSpace: "nowrap",
                        display: "inline-block"
                      }}
                    >
                      {t("common.btn.apply")}
                    </span>
                  }
                  backgroundColor="#05AE34"
                  color="white"
                  style={{
                    minWidth: "fit-content",
                    padding: "6px 16px"
                  }}
                />
              </div>
            </div>
          )}

          {/* {openSortModal && (
            <div
              ref={sortModalRef}
              className="fixed top-0 left-0"
              onClick={() => setOpenSortModal(false)}
              style={{
                // backgroundColor: "rgba(51, 51, 51, 0.8)",
                zIndex: 2,
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
              }}
            />
          )} */}
        </div>

        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "flex-end"
          }}
          id="filter-campaign"
        >
          <ButtonComponent
            className={cn(
              "MuiButtonBase-root MuiButton-root MuiButton-contained btn--rounder btn--primary",
              "text-uppercase MuiButton-containedSizeSmall MuiButton-sizeSmall",
              "relative"
            )}
            title={
              <>
                <span
                  className="MuiButton-label"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "fit-content"
                  }}
                >
                  <FilterIcon style={{ marginRight: "4px", flexShrink: 0 }} />{" "}
                  <p
                    style={{
                      minWidth: "fit-content"
                    }}
                    className="ml-1"
                  >
                    {t("common.btn.filter")}
                  </p>
                </span>
              </>
            }
            backgroundColor="#05AE34"
            color="white"
            handleClickButton={handleToggleFilterModal}
            style={{
              minWidth: "fit-content",
              padding: "6px 16px",
              background: "rgb(5, 174, 52)",
              color: "white"
            }}
          />

          {openFilterModal && (
            <div
              ref={filterModalRef}
              className="absolute left-0 w-full bg-white py-3"
              style={{
                zIndex: 3,
                position: "absolute",
                top: "100%",
                marginTop: "4px",
                borderRadius: "8px",
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: "#C1C1C1FF",
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
                minWidth: "fit-content",
                width: "100%"
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "0 12px",
                  marginBottom: "8px"
                }}
              >
                <p>{t("campaign_management.date_range")}</p>
                <p
                  onClick={handleResetDateRange}
                  style={{ color: "#23763AFF", cursor: "pointer" }}
                >
                  {t("common.btn.reset")}
                </p>
              </div>

              {/* chon ngay */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "0 8px"
                }}
              >
                <input
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  style={{
                    cursor: "pointer",
                    flex: 1,
                    marginRight: "8px",
                    padding: "8px",
                    borderRadius: "4px",
                    border: "1px solid #C1C1C1FF"
                  }}
                />
                <input
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  style={{
                    cursor: "pointer",
                    flex: 1,
                    marginLeft: "8px",
                    padding: "8px",
                    borderRadius: "4px",
                    border: "1px solid #C1C1C1FF"
                  }}
                />
              </div>

              {/* duong line */}
              <div
                style={{
                  width: "100%",
                  height: "1px",
                  marginTop: "12px",
                  marginBottom: "12px",
                  backgroundColor: "#C1C1C1FF"
                }}
              />

              {/* status */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "0 12px",
                  marginBottom: "8px"
                }}
              >
                <p>{t("campaign_management.status.title")}</p>
                <p
                  onClick={handleResetStatus}
                  style={{ color: "#23763AFF", cursor: "pointer" }}
                >
                  {t("common.btn.reset")}
                </p>
              </div>

              {/* select status */}
              {/* <div className="relative inline-block w-64 px-2"> */}
              <div
                style={{
                  position: "relative",
                  border: "1px solid #D1D5DB",
                  borderRadius: "0.375rem",
                  padding: "0.5rem 1rem",
                  cursor: "pointer",
                  marginLeft: "8px",
                  marginRight: "8px",
                  paddingLeft: "8px",
                  paddingRight: "8px"
                }}
                onClick={() => setIsOpen(true)}
              >
                {isOpen && (
                  <div
                    ref={filterPopupRef}
                    onClick={() => setIsOpen(false)}
                    style={{
                      position: "absolute",
                      top: "100%",
                      left: 0,
                      zIndex: 3,
                      width: "100%",
                      backgroundColor: "white",
                      border: "1px solid #D1D5DB",
                      borderRadius: "0.375rem",
                      boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
                      marginTop: "4px"
                    }}
                  >
                    <div style={{ padding: "0.25rem 0" }}>
                      {/* Checkbox for "All" option */}
                      <div
                        style={{
                          padding: "0.5rem 1rem",
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center"
                        }}
                        onClick={() => handleSelect("all")}
                      >
                        <div
                          style={{
                            position: "relative",
                            width: "20px",
                            height: "20px",
                            borderRadius: "4px",
                            backgroundColor:
                              selectedStatus.length === dataStatus.length
                                ? "#05ae34"
                                : "#ffffff",
                            border:
                              selectedStatus.length === dataStatus.length
                                ? "1px solid #f4fef4ff"
                                : "1px solid #c1c1c1",
                            marginRight: "8px"
                          }}
                        >
                          {selectedStatus.length === dataStatus.length && (
                            <div
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)"
                              }}
                            >
                              <CheckOutlined
                                style={{
                                  paddingLeft: "3px",
                                  paddingRight: "3px",
                                  fill: "#ffffff"
                                }}
                              />
                            </div>
                          )}
                        </div>
                        <span>{t("campaign_management.status.all")}</span>
                      </div>

                      {dataStatus.map((status) => (
                        <div
                          key={status.id}
                          style={{
                            padding: "0.5rem 1rem",
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center"
                          }}
                          onClick={() => handleSelect(status.name)}
                        >
                          <div
                            style={{
                              position: "relative",
                              width: "20px",
                              height: "20px",
                              borderRadius: "4px",
                              backgroundColor: selectedStatus.includes(
                                status.name
                              )
                                ? "#05ae34"
                                : "#ffffff",
                              border: selectedStatus.includes(status.name)
                                ? "1px solid #f4fef4ff"
                                : "1px solid #c1c1c1",
                              marginRight: "8px"
                            }}
                          >
                            {selectedStatus.includes(status.name) && (
                              <div
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)"
                                }}
                              >
                                <CheckOutlined
                                  style={{
                                    paddingLeft: "3px",
                                    paddingRight: "3px",
                                    fill: "#ffffff"
                                  }}
                                />
                              </div>
                            )}
                          </div>
                          <span>{status.value}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%"
                  }}
                >
                  <span>
                    {selectedStatus.length === 0
                      ? t("common.btn.choose_status")
                      : selectedStatus.length < dataStatus.length
                      ? selectedStatus
                          .map((status) =>
                            t(`campaign_management.status.${status}`)
                          )
                          .join(", ")
                      : t("campaign_management.status.all")}
                  </span>

                  <ChevronDown
                    style={{
                      width: "16px",
                      height: "16px",
                      transition: "transform 0.3s ease",
                      transform: isOpen ? "rotate(180deg)" : "rotate(0deg)"
                    }}
                  />
                </div>
              </div>
              {/* </div> */}

              <div
                style={{
                  width: "100%",
                  height: "1px",
                  marginTop: "12px",
                  marginBottom: "12px",
                  backgroundColor: "#C1C1C1FF"
                }}
              />

              {/* reset va apply all o filter */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "12px",
                  paddingLeft: "8px",
                  paddingRight: "8px"
                }}
              >
                <ButtonComponent
                  id="reset"
                  handleClickButton={handleResetAllFilter}
                  className={cn("btn--grayMain")}
                  title={
                    <span
                      style={{
                        minWidth: "fit-content"
                      }}
                      className="MuiButton-label"
                    >
                      {t("common.btn.reset_all")}
                    </span>
                  }
                  backgroundColor="#05AE34"
                  color="white"
                  style={{
                    minWidth: "fit-content",
                    padding: "6px 16px",
                    background: "#05ae34"
                  }}
                />
                <ButtonComponent
                  id="apply"
                  handleClickButton={handleClickFilter}
                  className={cn(
                    classes.buttonFilter,
                    "btn--rounder",
                    "btn--primary--filled"
                  )}
                  title={
                    <span
                      style={{
                        whiteSpace: "nowrap",
                        display: "inline-block"
                      }}
                    >
                      {t("common.btn.apply")}
                    </span>
                  }
                  backgroundColor="#05AE34"
                  color="white"
                  style={{
                    minWidth: "fit-content",
                    padding: "6px 16px"
                  }}
                />
              </div>
            </div>
          )}

          {/* {openFilterModal && (
            <div
              className="fixed top-0 left-0"
              onClick={() => setOpenFilterModal(false)}
              style={{
                // backgroundColor: "rgba(51, 51, 51, 0.8)",
                zIndex: 2,
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
              }}
            />
          )} */}
        </div>
      </div>
      {/* )} */}
      <LoadingWrapper
        isOpen={isLoadingList}
        style={{ width: "100%" }}
        id="list-scenario"
      >
        <TableContainer
          component={Paper}
          style={{
            boxShadow: "none",
            height: "calc(100vh - 350px)",
            overflowY: "auto",
            borderRadius: 0,
            width: "100%",
            border: "1px solid rgb(222, 225, 230)"
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow
                className={[classes.heightHeader]}
                style={{
                  width: "max-content"
                }}
              >
                <TableCell
                  align="center"
                  className={[classes.witdhOrd, classes.p0, classes.f600]}
                >
                  <div>{t("campaign_management.scenario.ord_number")}</div>
                </TableCell>
                <TableCell
                  align="center"
                  className={[classes.witdhId, classes.p0, classes.f600]}
                >
                  <div>{t("campaign_management.scenario.id")}</div>
                </TableCell>
                <TableCell
                  align="center"
                  className={[classes.widthName, classes.p0, classes.f600]}
                >
                  <div>{t("campaign_management.scenario.name_scenrio")}</div>
                </TableCell>
                <TableCell
                  align="center"
                  className={[classes.widthCreator, classes.p0, classes.f600]}
                >
                  <div>{t("campaign_management.scenario.creator")}</div>
                </TableCell>
                <TableCell
                  align="center"
                  className={[classes.widthDate, classes.p0, classes.f600]}
                >
                  <div>{t("campaign_management.scenario.created_at")}</div>
                </TableCell>
                <TableCell
                  align="center"
                  className={[
                    classes.widthStatisticBtn,
                    classes.p0,
                    classes.f600
                  ]}
                >
                  <div>{t("campaign_management.scenario.customers")}</div>
                </TableCell>
                <TableCell
                  align="center"
                  className={[classes.widthStatistic, classes.p0, classes.f600]}
                >
                  <div>
                    {t("campaign_management.scenario.customers_success")}
                  </div>
                </TableCell>
                <TableCell
                  align="center"
                  className={[classes.widthStatistic, classes.p0, classes.f600]}
                >
                  <div>
                    {t("campaign_management.scenario.customers_failed")}
                  </div>
                </TableCell>
                <TableCell
                  align="center"
                  className={[classes.widthStatistic, classes.p0, classes.f600]}
                >
                  <div>
                    {t("campaign_management.scenario.customers_not_send")}
                  </div>
                </TableCell>
                <TableCell
                  align="center"
                  className={[classes.widthStatistic, classes.p0, classes.f600]}
                >
                  <div>{t("campaign_management.scenario.messages")}</div>
                </TableCell>
                <TableCell
                  align="center"
                  className={[classes.widthStatistic, classes.p0, classes.f600]}
                >
                  <div>
                    {t("campaign_management.scenario.messages_success")}
                  </div>
                </TableCell>
                <TableCell
                  align="center"
                  className={[classes.widthStatistic, classes.p0, classes.f600]}
                >
                  <div>{t("campaign_management.scenario.messages_failed")}</div>
                </TableCell>
                <TableCell
                  align="center"
                  className={[classes.widthStatistic, classes.p0, classes.f600]}
                >
                  <div>
                    {t("campaign_management.scenario.messages_not_send")}
                  </div>
                </TableCell>
                {/* <TableCell
                  align="center"
                  className={[classes.widthStatus, classes.p0, classes.f600]}
                >
                  <div>{t("campaign_management.scenario.sending_status")}</div>
                </TableCell> */}

                <TableCell
                  align="center"
                  className={[classes.widthStatus, classes.p0, classes.f600]}
                  style={{
                    width: "100%",
                    position: "sticky",
                    right: 0,
                    top: 0,
                    backgroundColor: "#fff",
                    zIndex: 2
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      minWidth: "350px"
                    }}
                  >
                    <div
                      style={{
                        width: "30%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        whiteSpace: "nowrap"
                      }}
                    >
                      {t("campaign_management.scenario.sending_status")}
                    </div>
                    <div
                      style={{
                        width: "70%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        whiteSpace: "nowrap"
                      }}
                    >
                      {t("campaign_management.scenario.schedule_status")}
                    </div>
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {scenarioList?.content?.map((row, index) => {
                return (
                  <TableRow
                    onClick={() =>
                      onViewScenario(row.campaignId, row.id, row.pageId)
                    }
                    key={row.id}
                    className={[
                      classes.heightHeader,
                      classes.rowItem,
                      classes.clickableRow
                    ]}
                  >
                    <TableCell
                      align="center"
                      className={[classes.witdhOrd, classes.p0]}
                    >
                      <div>
                        {scenarioList?.pageable?.pageNumber *
                          defaultSizeGetScenario +
                          index +
                          1}
                      </div>
                    </TableCell>

                    <TableCell
                      align="center"
                      className={[classes.witdhId, classes.p0]}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between"
                        }}
                      >
                        <Tooltip title={row.id} aria-label="add">
                          <div
                            style={{
                              maxWidth: "120px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis"
                            }}
                          >
                            {row.id}
                          </div>
                        </Tooltip>
                        <Tooltip
                          title={isCopiedId ? "Copied!" : "Copy"}
                          aria-label="add"
                        >
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              navigator.clipboard.writeText(row.id);
                              setIsCopiedId(true);
                              setTimeout(() => {
                                setIsCopiedId(false);
                              }, 1000);
                            }}
                            size="small"
                            style={{ marginLeft: "14px" }}
                          >
                            <CoppyIcon height={"20px"} width={"20px"} />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </TableCell>

                    <TableCell
                      align="center"
                      className={[classes.widthName, classes.p0]}
                    >
                      {row.name ? (
                        <div>{row.name}</div>
                      ) : (
                        <p
                          style={{
                            fontStyle: "italic",
                            fontSize: "12px"
                          }}
                        >
                          {""}
                        </p>
                      )}
                    </TableCell>

                    <TableCell
                      style={{
                        minWidth: "86px"
                      }}
                      align="center"
                      className={[classes.widthCreator, classes.p0]}
                    >
                      {row.createdByUserId ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            position: "relative"
                          }}
                        >
                          <p
                            style={{
                              width: "100px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              fontSize: "14px"
                            }}
                          >
                            {row.userFullName}
                          </p>
                          {/* <ClickAwayListener
                          onClickAway={(e) => {
                            e.stopPropagation()
                            setCreatorShowUp(null)
                          }}
                        >
                          <HtmlTooltip
                            placement="top"
                            open={creatorShowUp?.scenarioId === row.id}
                            interactive={true}
                            title={
                              <UserInfoCard
                                scenarioId={creatorShowUp?.scenarioId}
                              />
                            }
                            PopperProps={{
                              disablePortal: true,
                            }}
                            disableFocusListener
                            disableHoverListener
                          >
                            <IconButton
                              size="small"
                              style={{ marginLeft: '14px' }}
                              onClick={(e) => {
                                e.stopPropagation()
                                console.log(row.createdByUserId)
                                setCreatorShowUp((prev) => {
                                  if (row?.id === prev?.scenarioId) {
                                    return null
                                  }
                                  return {
                                    id: row.createdByUserId,
                                    scenarioId: row.id,
                                  }
                                })
                              }}
                            >
                              <PermIdentity height={'20px'} width={'20px'} />
                            </IconButton>
                          </HtmlTooltip>
                        </ClickAwayListener> */}
                        </div>
                      ) : (
                        <p
                          style={{
                            fontStyle: "italic",
                            fontSize: "12px"
                          }}
                        >
                          {"_"}
                        </p>
                      )}
                    </TableCell>

                    <TableCell
                      align="center"
                      className={[classes.widthDate, classes.p0]}
                    >
                      {row.createdAt ? (
                        <div>
                          {moment(row.createdAt).format("DD/MM/YYYY HH:mm")}
                        </div>
                      ) : (
                        <p
                          style={{
                            fontStyle: "italic",
                            fontSize: "12px"
                          }}
                        >
                          {""}
                        </p>
                      )}
                    </TableCell>

                    <TableCell
                      align="center"
                      className={[classes.widthStatisticBtn, classes.p0]}
                    >
                      {row.totalCus ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                          }}
                        >
                          <p
                            style={{
                              fontSize: "14px"
                            }}
                          >
                            {row.totalCus}
                          </p>
                          {/* <IconButton
                              size="small"
                              style={{ marginLeft: '14px' }}
                              onClick={(e) => {
                                e.stopPropagation()
                                setScenarioCustomerSelected(row.id)
                              }}
                            >
                              <PeopleOutline height={'20px'} width={'20px'} />
                            </IconButton> */}
                        </div>
                      ) : (
                        <p
                          style={{
                            fontStyle: "italic",
                            fontSize: "12px"
                          }}
                        >
                          {""}
                        </p>
                      )}
                    </TableCell>

                    <TableCell
                      align="center"
                      className={[classes.widthStatistic, classes.p0]}
                    >
                      <div>{row.customerSuccess}</div>
                    </TableCell>

                    <TableCell
                      align="center"
                      className={[classes.widthStatistic, classes.p0]}
                    >
                      <div>{row.customerFailed}</div>
                    </TableCell>

                    <TableCell
                      align="center"
                      className={[classes.widthStatistic, classes.p0]}
                    >
                      <div>{row.customerNotSend}</div>
                    </TableCell>

                    <TableCell
                      align="center"
                      className={[classes.widthStatistic, classes.p0]}
                    >
                      <div>{row.totalMes}</div>
                    </TableCell>

                    <TableCell
                      align="center"
                      className={[classes.widthStatistic, classes.p0]}
                    >
                      <div>{row.messageSuccess}</div>
                    </TableCell>

                    <TableCell
                      align="center"
                      className={[classes.widthStatistic, classes.p0]}
                    >
                      <div>{row.messageFailed}</div>
                    </TableCell>

                    <TableCell
                      align="center"
                      className={[classes.widthStatistic, classes.p0]}
                    >
                      <div>{row.messageNotSend}</div>
                    </TableCell>

                    {/* <TableCell
                      align="center"
                      className={[classes.widthStatus, classes.p0]}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center"
                        }}
                      >
                        <CampaignStatusLabel
                          size="small"
                          type={row?.status}
                          message={t(
                            `campaign_management.scenario.status.${
                              campaignStatusDir[row?.status]
                            }`
                          )}
                        />
                      </div>
                    </TableCell>
                    <TableCell
                      align="center"
                      className={[classes.widthStatus, classes.p0]}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderLeft: "0.2px solid rgb(246, 239, 239)",
                        boxShadow: "none",
                        backgroundColor: "#fafafa",
                        position: "sticky",
                        right: 0,
                        top: 0,
                        zIndex: 1,
                        height: "52px"
                      }}
                    >
                      <div>
                        <TimeLabel
                          sendTime={
                            row?.schedule?.scheduleTime
                              ? parseInt(row?.schedule?.scheduleTime)
                              : null
                          }
                        />
                      </div>
                    </TableCell> */}
                    <TableCell
                      align="center"
                      className={[classes.widthStatus, classes.p0]}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderLeft: "0.2px solid rgb(246, 239, 239)",
                        boxShadow: "none",
                        backgroundColor: "#fafafa",
                        position: "sticky",
                        right: 0,
                        top: 0,
                        zIndex: 1,
                        height: "52px",
                        width: "100%"
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%"
                        }}
                      >
                        <div
                          style={{
                            width: "30%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            whiteSpace: "nowrap"
                          }}
                        >
                          <CampaignStatusLabel
                            size="small"
                            type={row?.status}
                            message={t(
                              `campaign_management.scenario.status.${
                                campaignStatusDir[row?.status]
                              }`
                            )}
                          />
                        </div>
                        <div
                          style={{
                            width: "70%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            whiteSpace: "nowrap"
                          }}
                        >
                          <TimeLabel
                            sendTime={
                              row?.schedule?.scheduleTime
                                ? parseInt(row?.schedule?.scheduleTime)
                                : null
                            }
                          />
                        </div>
                      </div>
                    </TableCell>

                    {/* action detail */}
                    {/* <TableCell
                    align="center"
                    className={[classes.widthActions, classes.p0]}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderLeft: "0.2px solid rgb(246, 239, 239)",
                      boxShadow: "none",
                      backgroundColor: "#fafafa",
                      position: "sticky",
                      right: 0,
                      top: 0,
                      zIndex: 2,
                      height: "52px"
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "8px 0"
                      }}
                    >
                      <Tooltip title={t("overall.lastest_scenario.see_detail")}>
                        <Button
                          style={{
                            background: "none",
                            padding: 0
                          }}
                          onClick={() =>
                            onViewScenario(row.campaignId, row.id, row.pageId)
                          }
                        >
                          <div className={classes.actionBtnDetail}>
                            <p className={classes.actionTitle}>
                              {t("common.btn.detail")}
                            </p>
                          </div>
                        </Button>
                      </Tooltip>
                    </div>
                  </TableCell> */}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </LoadingWrapper>
      {/* <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <Flex className={classes.paginationBottom}>
          <Pagination
            style={{
              marginRight: "20px",
              marginBottom: "20px"
            }}
            count={Math.ceil(scenarioList?.totalMatch / defaultSizeGetScenario)}
            page={page + 1}
            onChange={(event, newPage) => handleChangePage(event, newPage - 1)}
            disabled={scenarioList?.totalMatch === 0}
            color="secondary"
          />
        </Flex>
      </div> */}
      {/* {rows.length === 0 && (
        <p className={classes.noData}>{t("update.no_data")}</p>
      )} */}
      {/* {isPagination && rows.length > 0 && ( */}
      <Flex justify="space-between" align="center" className="px-3 py-2">
        <TablePagination
          style={{ margin: "0px 0" }}
          rowsPerPageOptions={[types.PAGINATION.SIZE, 20, 30, 50]}
          component="div"
          rowsPerPage={rowsPerPage}
          count={Math.ceil(scenarioList?.numberOfElements / rowsPerPage)}
          page={page + 1}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage={t("update.display")}
        />
        <Pagination
          style={{ top: 0 }}
          count={Math.ceil(scenarioList?.totalMatch / rowsPerPage)}
          page={page + 1}
          onChange={handleChange}
        />
      </Flex>
      {/* )} */}
    </div>
  );
};

const CellSticky = styled(TableCell)`
  position: sticky;
  background-color: #fff;
  right: 0;
  top: 0;
  z-index: 2;
  box-shadow: -15px 0px 17px -7px #f0eded;
`;

export default ScenarioList;
