import { Card, CardActions, CardContent } from "@material-ui/core";
import Flex from "components/Flex";
import pathKeys from "constants/pathKeys";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import styles from "../../styles.module.scss";
import { Doughnut } from "react-chartjs-2";
import cn from "classnames";
import { useTranslation } from "react-i18next";

const option_default = {
  responsive: true,
  maintainAspectRatio: false,
  animation: {
    animateRotate: false,
    animateScale: false
  },
  cutout: 60,
  plugins: {
    legend: {
      position: "left",
      align: "middle",
      labels: {
        usePointStyle: true,
        generateLabels: function (chart) {
          var labels = chart.data.labels;
          var dataset = chart.data.datasets[0];
          var legend = labels.map(function (label, index) {
            return {
              datasetIndex: 0,
              fillStyle:
                dataset.backgroundColor && dataset.backgroundColor[index],
              strokeStyle: dataset.borderColor && dataset.borderColor[index],
              lineWidth: dataset.borderWidth,
              text: `${label}: ${dataset.data[index]}`
            };
          });
          return legend;
        }
      }
    }
  }
};

const options_status = {
  ...option_default
};

const options_result = {
  ...option_default,
  plugins: {
    ...option_default.plugins,
    legend: {
      ...option_default.plugins.legend,
      position: "right"
    }
  }
};

export const DashboardEmpty = () => {
  const { t } = useTranslation();
  return (
    <Flex
      align="center"
      justify="center"
      style={{ width: "100%" }}
      className={styles.not_data}
    >
      {t("update.no_data")}
    </Flex>
  );
};

const Chart = ({ data }) => {
  const { t, i18n } = useTranslation();

  const data_status = useMemo(() => {
    if (data) {
      const { campaign } = data;

      return {
        labels: [
          t("overall.campaign_and_message.sent"),
          t("overall.campaign_and_message.unsent"),
          t("overall.campaign_and_message.sending"),
          t("overall.campaign_and_message.cancel")
        ],
        datasets: [
          {
            data: [
              campaign?.totalCampaignDone,
              campaign?.totalCampaignPending,
              campaign?.totalCampaignRunning,
              campaign?.totalCampaignCancel
            ],
            backgroundColor: ["#05ae34", "#ffc107", "#6610f2", "#bdbdbd"],
            borderWidth: 0
          }
        ]
      };
    }
    return null;
  }, [data, i18n.language]);

  const data_result = useMemo(() => {
    if (data) {
      console.log("🚀 ~ constdata_result=useMemo ~ data:", data);
      const { fanpage } = data;
      return {
        labels: [
          t("overall.campaign_and_message.sent_success"),
          t("overall.campaign_and_message.sent_failed"),
          t("overall.campaign_and_message.unsent")
        ],

        datasets: [
          {
            data: [
              fanpage?.totalSuccess,
              fanpage?.totalFail,
              fanpage?.totalNotSend
            ],
            backgroundColor: ["#05ae34", "#dc3545", "#666666"],
            borderWidth: 0
          }
        ]
      };
    }
    return null;
  }, [data]);

  // const totalCampaign = useMemo(() => {
  //   if (Object.keys(data?.campaign).length) {
  //     const { totalCampaignDone, totalCampaignPending, totalCampaignRunning } =
  //       data.campaign;
  //     return totalCampaignDone + totalCampaignPending + totalCampaignRunning;
  //   }
  //   return 0;
  // }, [data]);

  const textCampaign = useMemo(() => {
    return {
      id: "text-center",
      beforeDatasetsDraw(chart, args, pluginOptions) {
        const { ctx } = chart;

        ctx.save();
        ctx.font = "600 14px sans-serif";
        ctx.fillStyle = "#000000de";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillText(
          `${data?.campaign?.totalCampaigns || 0}\n${t("update.campaign")}`,
          chart.getDatasetMeta(0).data[0].x,
          chart.getDatasetMeta(0).data[0].y
        );
      }
    };
  }, [data?.campaign?.totalCampaigns]);

  const textMessage = useMemo(() => {
    return {
      id: "text-message",
      beforeDatasetsDraw(chart, args, pluginOptions) {
        const { ctx } = chart;

        ctx.save();
        ctx.font = "600 14px sans-serif";
        ctx.fillStyle = "#000000de";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillText(
          `${data?.fanpage?.totalSent || 0} ${t("update.message")}`,
          chart.getDatasetMeta(0).data[0].x,
          chart.getDatasetMeta(0).data[0].y
        );
      }
    };
  }, [data?.fanpage?.totalSent]);

  return (
    <div className={styles.box}>
      <Card
        style={{
          borderRadius: "12px"
        }}
        className={cn("ml-4")}
      >
        <CardContent className="pt-4">
          <h3>{t("overall.campaign_and_message.title")}</h3>
          {!data?.campaign?.totalCampaigns ? (
            <DashboardEmpty />
          ) : (
            <div>
              <Flex justify="center" className="w-100">
                <Flex className={cn(styles.chart_container)} justify="center">
                  <div className={cn(styles.chart, styles.chart_status)}>
                    <Doughnut
                      data={data_status}
                      options={options_status}
                      plugins={[textCampaign]}
                    />
                  </div>
                  <div className={cn(styles.chart, styles.chart_result)}>
                    <Doughnut
                      data={data_result}
                      options={options_result}
                      plugins={[textMessage]}
                    />
                  </div>
                </Flex>
              </Flex>
              <Flex justify="center">
                <Link
                  className={cn(styles.box__action)}
                  to={`${pathKeys.CAMPAIGN}`}
                >
                  {t("common.btn.campaign")}
                </Link>
              </Flex>
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default Chart;
