import React, { useEffect, useMemo, useState } from 'react'
import homeApi from '../../../../api/homeApi'
import { Card } from '@material-ui/core'
import { Doughnut } from 'react-chartjs-2'
import { useForm } from 'react-hook-form'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import LegendItem from './LegendItem'
import numeral from 'numeral'
import Flex from '../../../../components/Flex'

const GenAIChart = ({ style, ...res }) => {
  const [data, setData] = useState(null)
  const { t } = useTranslation()

  const { watch } = useForm({
    defaultValues: {
      date: [new Date(), new Date()],
    },
  })

  useEffect(() => {
    ;(async () => {
      try {
        const date = watch('date')

        const res = await homeApi.getGenAIStatistic({
          from: moment(date[0]).format('YYYY-MM-DD'),
          to: moment(date[1]).format('YYYY-MM-DD'),
        })

        setData(res)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [watch('date')])

  const totalMessages = useMemo(() => {
    return (
      (data?.totalCreatedMessages || 0) +
      (data?.totalFormattedMessages || 0) +
      (data?.totalSelectedMessages || 0)
    )
  }, [data])

  const dataGenAI = useMemo(() => {
    return {
      labels: [
        `${t('dashboard.created_content')}`,
        `${t('dashboard.customized_content')}`,
        `${t('dashboard.selected_content')}`,
      ],
      datasets: [
        {
          data: [
            data?.totalCreatedMessages || 0,
            data?.totalFormattedMessages || 0,
            data?.totalSelectedMessages || 0,
          ],
          backgroundColor: ['#ff6f91', '#ffb74d', '#81c784'],
          hoverBackgroundColor: ['#ff3d67', '#ff9800', '#4caf50'],
        },
      ],
    }
  }, [data])

  const doughnutOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        position: 'right',
      },
    },
  }

  const textOverlay = {
    id: 'text-center',
    beforeDatasetsDraw(chart) {
      const { ctx } = chart
      ctx.save()
      ctx.font = '600 14px sans-serif'
      ctx.fillStyle = '#000000de'
      ctx.textAlign = 'center'
      ctx.textBaseline = 'middle'
      ctx.fillText(
        `${numeral(totalMessages).format('0.[0]a') || 0}`,
        chart.getDatasetMeta(0).data[0].x,
        chart.getDatasetMeta(0).data[0].y
      )
      ctx.restore()
    },
  }

  const isEmpty = useMemo(() => {
    let sum = 0
    for (const property in data) {
      sum += data[property]
    }

    return sum === 0 ? true : false
  }, [data])

  return (
    <Card style={style} {...res}>
      <h3
        style={{
          margin: 0,
          textAlign: 'left',
          fontSize: '18px',
          fontWeight: '600',
        }}
      >
        {t('BOMBOT GENAI')}
      </h3>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        {isEmpty ? (
          <Flex align="center" justify="center" style={{ width: '100%' }}>
            <p>{t('dashboard.no_data')}</p>
          </Flex>
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                marginRight: '16px',
              }}
            >
              <Doughnut
                data={dataGenAI}
                options={doughnutOptions}
                plugins={[textOverlay]}
              />
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'center',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <LegendItem
                legendColor={dataGenAI.datasets[0].backgroundColor[0]}
                number={data?.totalCreatedMessages}
                percent={`${parseFloat(data?.totalCreatedMessages).toFixed(
                  2
                )} %`}
                title={t('dashboard.created_content')}
              />

              <LegendItem
                legendColor={dataGenAI.datasets[0].backgroundColor[1]}
                number={data?.totalFormattedMessages}
                percent={`${parseFloat(data?.totalFormattedMessages).toFixed(
                  2
                )} %`}
                title={t('dashboard.customized_content')}
              />

              <LegendItem
                legendColor={dataGenAI.datasets[0].backgroundColor[2]}
                number={data?.totalSelectedMessages}
                percent={`${parseFloat(data?.totalSelectedMessages).toFixed(
                  2
                )} %`}
                title={t('dashboard.selected_content')}
              />
            </div>
          </div>
        )}
      </div>
    </Card>
  )
}

export default GenAIChart
