import { Button, ButtonGroup } from "@material-ui/core";
import Flex from "../Flex";
import { useTranslation } from "react-i18next";

const FiltersContainer = ({ options, selectedFilter, handleFilterChange }) => {
  const { t } = useTranslation();

  return (
    <Flex
      align="center"
      justify="flex-start"
      style={{
        padding: "16px",
        backgroundColor: "##F4F4F4FF"
      }}
    >
      <h2 style={{ margin: "0 16px 0 0", fontSize: "18px", color: "#333" }}>
        {t("common.btn.filter")}
      </h2>
      <ButtonGroup
        variant="contained"
        color="primary"
        aria-label="outlined primary button group"
        size="small"
        style={{
          gap: "8px",
          boxShadow: "none"
        }}
      >
        {options.map((option) => {
          const { color, value, label } = option;

          return (
            <Button
              onClick={() => handleFilterChange(value)}
              variant={selectedFilter === value ? "contained" : "outlined"}
              style={{
                borderRadius: "4px",
                padding: "4px 8px",
                fontSize: "12px",
                borderColor: "#e0e0e0",
                borderWidth: "1px",
                boxShadow: "none",
                backgroundColor:
                  selectedFilter === value ? color || "#007bff" : "#fff",
                color: selectedFilter === value ? "#fff" : color || "#007bff"
              }}
            >
              {label}
            </Button>
          );
        })}
        {/* <Button
          onClick={() => handleFilterChange('NEXT_7D')}
          variant={selectedFilter === 'NEXT_7D' ? 'contained' : 'outlined'}
          style={{
            borderRadius: '4px',
            padding: '4px 8px',
            fontSize: '12px',
            borderColor: '#e0e0e0',
            borderWidth: '1px',
            boxShadow: 'none',
            backgroundColor: selectedFilter === 'NEXT_7D' ? '#007bff' : '#fff',
            color: selectedFilter === 'NEXT_7D' ? '#fff' : '#007bff',
          }}
        >
          7 Ngày tiếp theo
        </Button>
        <Button
          onClick={() => handleFilterChange('NEXT_24H')}
          variant={selectedFilter === 'NEXT_24H' ? 'contained' : 'outlined'}
          style={{
            borderRadius: '4px',
            padding: '4px 8px',
            fontSize: '12px',
            borderColor: '#e0e0e0',
            borderWidth: '1px',
            boxShadow: 'none',
            backgroundColor: selectedFilter === 'NEXT_24H' ? '#007bff' : '#fff',
            color: selectedFilter === 'NEXT_24H' ? '#fff' : '#007bff',
          }}
        >
          24h Tiếp theo
        </Button>
        <Button
          onClick={() => handleFilterChange('NEXT_14D')}
          variant={selectedFilter === 'NEXT_14D' ? 'contained' : 'outlined'}
          style={{
            borderRadius: '4px',
            padding: '4px 8px',
            fontSize: '12px',
            borderColor: '#e0e0e0',
            borderWidth: '1px',
            boxShadow: 'none',
            backgroundColor: selectedFilter === 'NEXT_14D' ? '#007bff' : '#fff',
            color: selectedFilter === 'NEXT_14D' ? '#fff' : '#007bff',
          }}
        >
          14 Ngày tiếp theo
        </Button>
        <Button
          onClick={() => handleFilterChange('NEXT_30D')}
          variant={selectedFilter === 'NEXT_30D' ? 'contained' : 'outlined'}
          style={{
            borderRadius: '4px',
            padding: '4px 8px',
            fontSize: '12px',
            borderColor: '#e0e0e0',
            borderWidth: '1px',
            boxShadow: 'none',
            backgroundColor: selectedFilter === 'NEXT_30D' ? '#007bff' : '#fff',
            color: selectedFilter === 'NEXT_30D' ? '#fff' : '#007bff',
          }}
        >
          30 Ngày tiếp theo
        </Button>
        <Button
          onClick={() => handleFilterChange('ALL')}
          variant={selectedFilter === 'ALL' ? 'contained' : 'outlined'}
          style={{
            borderRadius: '4px',
            padding: '4px 8px',
            fontSize: '12px',
            borderColor: '#e0e0e0',
            borderWidth: '1px',
            boxShadow: 'none',
            backgroundColor: selectedFilter === 'ALL' ? '#007bff' : '#fff',
            color: selectedFilter === 'ALL' ? '#fff' : '#007bff',
          }}
        >
          Tất cả
        </Button> */}
      </ButtonGroup>
    </Flex>
  );
};

export default FiltersContainer;
