import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import {
  HourglassEmpty,
  PlayCircleFilled,
  PauseCircleFilled,
  CheckCircle,
} from '@material-ui/icons'

// Styles
const useStyles = makeStyles((theme) => ({
  base: {
    padding: theme.spacing(1.5), // Padding mặc định
    borderRadius: '4px',
    margin: theme.spacing(1, 0),
    display: 'flex',
    alignItems: 'center',
  },
  pending: {
    color: 'blue',
    backgroundColor: 'rgba(33, 150, 243, 0.2)', // Xanh dương nhạt
  },
  running: {
    color: 'teal',
    backgroundColor: 'rgba(0, 128, 128, 0.2)', // Màu xanh ngọc nhạt
  },
  cancel: {
    color: 'orange',
    backgroundColor: 'rgba(255, 152, 0, 0.2)', // Cam nhạt
  },
  done: {
    color: 'darkgreen',
    backgroundColor: 'rgba(34, 139, 34, 0.2)', // Xanh lá đậm nhạt
  },
  small: {
    padding: theme.spacing(1), // Padding nhỏ
    fontSize: '12px !important',
    '& $icon': {
      fontSize: '16px !important',
    },
  },
  medium: {
    padding: '8px 12px', // Padding vừa
    fontSize: '14px !important',
    '& $icon': {
      fontSize: '20px !important',
    },
  },
  large: {
    padding: theme.spacing(2), // Padding lớn
    fontSize: '16px !important',
    '& $icon': {
      fontSize: '24px !important',
    },
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  textsmall: {
    fontSize: '12px !important',
  },
  textmedium: {
    fontSize: '14px !important',
  },
  textlarge: {
    fontSize: '16px !important',
  },
}))

// Component
const CampaignStatusLabel = ({
  message,
  type,
  size = 'medium',
  icon,
  ...res
}) => {
  const classes = useStyles()

  // Map trạng thái sang class
  let statusClass
  let IconComponent

  if (type === 'pending') {
    statusClass = classes.pending
    IconComponent = HourglassEmpty
  } else if (type === 'running') {
    statusClass = classes.running
    IconComponent = PlayCircleFilled
  } else if (type === 'cancel') {
    statusClass = classes.cancel
    IconComponent = PauseCircleFilled
  } else if (type === 'done') {
    statusClass = classes.done
    IconComponent = CheckCircle
  }

  // Kết hợp class của kích thước
  const sizeClass = classes[size] || classes.medium

  let textStatusClass = classes[`text${size}`]

  return IconComponent ? (
    <div
      {...res}
      className={`${classes.base} ${statusClass} ${sizeClass}`}
      style={{ width: 'fit-content' }}
    >
      {icon && <IconComponent className={classes.icon} />}
      <p className={`${textStatusClass}`}>{message}</p>
    </div>
  ) : null
}

// PropTypes
CampaignStatusLabel.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['pending', 'running', 'cancel', 'done']).isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
}

CampaignStatusLabel.defaultProps = {
  size: 'medium',
}

// Export trạng thái
export const campaignStatusDir = {
  pending: 'Initial',
  running: 'Running',
  cancel: 'Paused',
  done: 'Completed',
}

export default CampaignStatusLabel
