import React, { useEffect, useMemo, useRef, useState } from 'react'
import _ from 'lodash'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import MainLayout from '../MainLayout'
import FormControl from '@material-ui/core/FormControl'
import GetAppIcon from '@material-ui/icons/GetApp'
import ListCustomerComponent from '../../components/ListCustomer'
import FormDialog from '../../components/FormDialog'
import ButtonComponent from '../../components/Button'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormLabel from '@material-ui/core/FormLabel'
import { Button, Chip } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Checkbox from '@material-ui/core/Checkbox'
import types from '../../constants/types'
import moment from 'moment'
import IMAGES from '../../images'
import Autocomplete from '@material-ui/lab/Autocomplete'
import DeleteIcon from '@material-ui/icons/Delete'
import Loading from '../../components/Loading'
import { getFanpageDetail } from 'views/Fanpage/FanpageActions'
import { useDispatch, useSelector } from 'react-redux'
import Flex from 'components/Flex'
import BaseSelectFile from 'components/BaseSelectFile'
import cn from 'classnames'
import { selectFilterCus, selectImportResult } from './ScenarioSelectors'
import Tour from 'reactour'
import { selectSearchUidName } from './ScenarioSelectors'
import { getIsGuideByKey, GUIDE_TYPE_ENUM, setIsGuideByKey } from 'utils/helper'
import { selectGuideListByKey } from 'stores/config/config.selector'
import ModalResultImport from './item/ModalResultImport'
import { useTranslation } from 'react-i18next'
import MessengerButton from '../Home/items/MessengerButton'
import TagPreviewItem from '../../components/TagPreviewItem'
import { colorDir1, getRandomTag } from '../../components/TagPreviewModal'
import ScenarioList from './components/ScenarioList'

const useStyles = makeStyles((theme) => ({
  wrapSelectFanpage: {
    marginTop: 30,
    width: 'calc(100% - 15px)',
    '& h4': {
      marginBottom: 5,
      color: '#05AE34',
      fontSize: 16,
    },
  },
  wrapSelect: {
    width: '100%',
  },
  select: {
    // margin: "5px 0px !important",
  },
  wrapSelectLabel: {
    zIndex: 1,
    fontSize: 16,
    paddingLeft: 10,
    marginTop: 7,
    '&.MuiFormLabel-filled': {
      display: 'none !important',
    },
    '&.Mui-focused': {
      display: 'none !important',
    },
  },

  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#05AE34',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
  wrapRadioGroup: {
    display: 'inline-block',
    flexWrap: 'inherit',
    flexDirection: 'inherit',
    marginLeft: 15,
    fontSize: '16px',
    marginTop: 10,
  },
  // wrapperPopup: {
  //   "& h4": {
  //     color: "#05AE34",
  //     paddingLeft: 15,
  //   },
  //   "& .MuiInputLabel-filled.MuiInputLabel-shrink": {
  //     top: "-25px",
  //     color: "rgba(0, 0, 0, 0.87) !important",
  //     width: "100%",
  //     fontSize: "16px",
  //     transform: "inherit",
  //   },
  //   "& .MuiInputLabel-filled": {
  //     top: "-25px",
  //     color: "rgba(0, 0, 0, 0.87) !important",
  //     width: "100%",
  //     fontSize: "16px",
  //     transform: "inherit",
  //   },
  //   "& .MuiFilledInput-input": {
  //     padding: "10px 12px 10px 2px !important",
  //   },
  // },
  // radioGroup: {
  //   flexWrap: "inherit",
  //   flexDirection: "inherit",
  //   "& label": {
  //     float: "left",
  //   },
  // },
  // textField: {
  //   width: "100%",
  //   padding: "0px 15px",
  //   margin: "35px 0px 10px 0px",
  //   "& .MuiInputLabel-filled": {
  //     marginLeft: 15,
  //   },
  // },
  formControlCheckbox: {
    margin: '15px 0px 0px 15px',
    width: 'calc(100% - 30px)',
    '& .MuiFormLabel-root': {
      marginBottom: 5,
      fontSize: 16,
    },
    '& .MuiFormControlLabel-label': {
      fontSize: 16,
    },
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: '#05AE34',
    },
    '& .MuiFormLabel-root': {
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: 16,
    },
  },
  wrapButton: {
    width: '100%',
    display: 'inline-block',
  },
  button: {
    display: 'inline-block',
    width: 130,
    float: 'right',
    margin: '30px 15px 30px 0px',
  },
  title: {
    color: '#05AE34',
  },
  wrapButtonImport: {
    textAlign: 'right',
  },
  wrapBox: {
    padding: '15px 42px 30px 15px',
  },
  buttonChange: {
    margin: '0px 0px 0px 10px',
  },
  importInput: {
    margin: '15px 0px 30px 0px',
  },
  inboxDate: {
    left: 15,
    top: -5,
    width: 200,
    marginTop: 25,
    // marginwrapperPopupwrapperPopupTop: 25,
    '& label': {
      color: 'rgba(0, 0, 0, 0.87) !important',
      fontSize: '16px',
      transform: 'inherit',
      top: '-10px',
      width: '100%',
    },
    '& .MuiInputBase-formControl': {
      width: 140,
    },
    '& $root': {
      width: 120,
    },
    '& .MuiInputBase-input': {
      width: 120,
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      opacity: 0,
      cursor: 'pointer',
      boxSizing: 'border-box',
    },
    '& .MuiInputBase-input::-webkit-calendar-picker-indicator': {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      margin: 0,
      padding: 0,
      cursor: 'pointer',
    },
  },
  inboxTime: {
    left: 15,
    top: -5,
    width: 200,
    marginTop: 25,
    // marginwrapperPopupwrapperPopupTop: 25,
    '& label': {
      color: 'rgba(0, 0, 0, 0.87) !important',
      fontSize: '16px',
      transform: 'inherit',
      top: '-10px',
      width: '100%',
    },
    '& .MuiInputBase-formControl': {
      width: 140,
    },
    '& $root': {
      width: 120,
    },
  },
  inboxTimeLast: {
    right: 30,
    position: 'absolute',
    top: -5,
    marginTop: 25,
    // marginwrapperPopupwrapperPopupTop: 25,
    '& label': {
      color: 'rgba(0, 0, 0, 0.87) !important',
      fontSize: '16px',
      transform: 'inherit',
      top: '-10px',
      width: '100%',
    },
    '& .MuiInputBase-formControl': {
      width: 140,
    },
    '& $root': {
      width: 120,
    },

    '& .MuiInputBase-input::-webkit-calendar-picker-indicator': {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      margin: 0,
      padding: 0,
      cursor: 'pointer',
    },
  },
  wrapperLoading: {
    height: 100,
    position: 'relative',
    textAlign: 'center',
    top: -20,
    '& img': {
      height: 100,
      position: 'absolute',
      top: 0,
      left: 'calc(50% - 50px)',
      width: 100,
    },
    '& p': {
      position: 'absolute',
      bottom: -11,
      textAlign: 'center',
      width: '100%',
    },
  },
  noData: {
    marginTop: 15,
    marginBottom: 10,
    color: 'rgba(0, 0, 0, 0.38)',
  },
  addTag: {
    width: '100%',
    marginBottom: 15,
  },
  wrapCheckbox: {
    position: 'relative',
    '&:hover': {
      '& $delete': {
        display: 'inline-block',
      },
    },
  },
  delete: {
    display: 'none',
    position: 'absolute',
    right: 0,
    top: 0,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  wrapButtonPopup: {
    textAlign: 'right',
    paddingBottom: 30,
    paddingRight: 15,
  },
  tagFormGroup: {
    height: '200px',
    overflowY: 'auto',
    overflowX: 'hidden',
    display: 'initial',
    marginTop: '15px',
    '& .MuiFormControlLabel-root': {
      width: '100%',
    },
  },
  fromToIcon: {
    position: 'absolute',
    top: '45px',
    left: '180px',
  },
}))

function StyledRadio(props) {
  const classes = useStyles()

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  )
}

const CustomerView = (props) => {
  const classes = useStyles()

  const { t } = useTranslation()

  const [campaignId, setCampaignId] = useState(null)

  const {} = props

  useEffect(() => {
    const searchParam = new URLSearchParams(window.location.search)

    setCampaignId(searchParam.get('campaignId'))
  }, [])

  const tourOverview = useSelector(
    selectGuideListByKey(GUIDE_TYPE_ENUM.scenario_manager)
  )

  const [tourConfig, setTourConfig] = useState(null)

  useEffect(() => {
    if (
      getIsGuideByKey(GUIDE_TYPE_ENUM.scenario_manager) &&
      tourOverview?.length
    ) {
      setTourConfig(tourOverview)
      setIsGuideByKey(GUIDE_TYPE_ENUM.scenario_manager)
    }
  }, [tourOverview])

  return (
    <MainLayout>
      <Tour
        steps={tourConfig}
        isOpen={tourConfig != null}
        closeWithMask={false}
        lastStepNextButton={
          <Button size="small" className="btn--rounder" variant="contained">
            {t('common.btn.close')}
          </Button>
        }
        accentColor="#05ae34"
        onRequestClose={() => setTourConfig(null)}
      />
      {/* <input type="file" onChange={fileHandler} style={{"padding":"10px"}} /> */}
      {/* {loading && <Loading />} */}
      <ScenarioList
        campaignId={campaignId} />
    </MainLayout>
  )
}

CustomerView.propTypes = {}

CustomerView.defaultProps = {}

export default CustomerView
