import { CircularProgress } from '@material-ui/core'
import React from 'react'

const LoadingWrapper = ({ children, isOpen, style, ...res }) => {
  return (
    <div style={{ position: 'relative', ...style }} {...res}>
      {children}
      {isOpen && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            zIndex: 99,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
            top: 0,
            right: 0,
          }}
        >
          <CircularProgress color="inherit" />
        </div>
      )}
    </div>
  )
}

export default LoadingWrapper
