import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import SidebarContainer from "../Sidebar";
import HeaderContainer from "../Header";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import ChangeLanguage from "components/ChangeLanguage";

const useStyles = makeStyles((theme) => ({
  root: {},
  sidebar: {
    width: 250,
    float: "left",
    height: "100vh",
    overflowY: "auto",
    boxShadow: "0 0 2rem 0 rgba(136,152,170,.15)!important",
    background: "white",
    position: "fixed",
    top: 0,
    left: 0,
    transition: "width 0.3s ease, left 0.3s ease",
  },
  sidebarDehaze: {
    width: 60,
    float: "left",
    height: "100vh",
    overflowY: "auto",
    boxShadow: "0 0 2rem 0 rgba(136,152,170,.15)!important",
    background: "white",
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 10,
    transition: "width 0.3s ease, left 0.3s ease",
  },
  contentRight: {
    width: "calc(100% - 250px)",
    height: "calc(100vh - 70px - 73px)",
    overflow: "auto",
    float: "right",
    paddingLeft: 15,
    paddingRight: 15,
    marginTop: 76,
    transition: "width 0.3s ease",
  },
  contentRightDehaze: {
    width: "calc(100% - 60px)",
    height: "calc(100vh - 70px - 73px)",
    overflow: "auto",
    float: "right",
    paddingLeft: 15,
    paddingRight: 15,
    marginTop: 76,
    transition: "width 0.3s ease",
  },
  footer: {
    height: 70,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: 15,
    paddingLeft: 15,
    color: "#05ae34",
    bottom: 0,
    width: "calc(100% - 280px)",
    background: "#F6F9FC",
    position: "fixed",
    zIndex: 99,
    transition: "width 0.3s ease",
  },
  footerDehaze: {
    height: 70,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: 15,
    paddingLeft: 15,
    color: "#05ae34",
    bottom: 0,
    width: "calc(100% - 90px)", // Width when sidebar is collapsed
    background: "#F6F9FC",
    position: "fixed",
    zIndex: 99,
    transition: "width 0.3s ease",
  },
  right: {
    display: "flex",
    alignItems: "center",
    "& a": {
      marginLeft: 15,
      color: "#05ae34",
      textDecoration: "none",
    },
  },
}));

const MainLayoutView = (props) => {
  const { children, dehaze } = props;
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const configPage = useMemo(() => {
    if (i18n.language?.substring(0, 2) === "kh") {
      return {
        page_id: "102636822160260",
        app_id: "4087907114572119",
      };
    } else {
      return {
        page_id: "103634791744953",
        app_id: "4087907114572119",
      };
    }
  }, [i18n.language]);

  return (
    <div className={classes.root}>
      <div className={cn(dehaze ? classes.sidebarDehaze : classes.sidebar)}>
        <SidebarContainer />
      </div>
      <div
        className={cn(
          dehaze ? classes.contentRightDehaze : classes.contentRight
        )}
      >
        <HeaderContainer />
        <div className={classes.content}>{children}</div>
        <div className={cn(dehaze ? classes.footerDehaze : classes.footer)}>
          <div className={classes.left}>
            &copy; 2021{" "}
            <span style={{ fontWeight: "bold", color: "rgb(5, 174, 52)" }}>
              @Bombot.vn
            </span>
          </div>
          <div className={classes.right}>
            <ChangeLanguage isUser ={true} />
            <a href="https://bombot.vn/" target="_blank" rel="noreferrer">
              {t("footer.home")}
            </a>
            <a
              href="https://partner.bombot.vn/"
              target="_blank"
              rel="noreferrer"
            >
              {t("footer.affiliate")}
            </a>
            <a
              href="https://bombot.vn/chinh-sach-dich-vu/"
              target="_blank"
              rel="noreferrer"
            >
              {t("footer.policy")}
            </a>
            <a
              href="https://bombot.vn/dieu-khoan-bao-mat"
              target="_blank"
              rel="noreferrer"
            >
              {t("footer.private_policy")}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

MainLayoutView.propTypes = {};

MainLayoutView.defaultProps = {};

export default MainLayoutView;