import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MainLayout from '../MainLayout'
import ListCampaignComponent from '../../components/ListCampaign'
import Loading from '../../components/Loading'
import MessengerButton from './../Home/items/MessengerButton'
import Tour from 'reactour'
import { useSelector } from 'react-redux'
import {
  getIsGuideByKey,
  GUIDE_TYPE_ENUM,
  setIsGuideByKey,
} from '../../utils/helper'
import { selectGuideListByKey } from '../../stores/config/config.selector'
import { useTranslation } from 'react-i18next'
import { Button } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({}))
const CampaignView = (props) => {
  const { getCampaign, campaign, loading } = props
  const [campaignData, setCampaignData] = useState([])

  const { t } = useTranslation()

  useEffect(() => {
    setCampaignData(campaign)
  }, [campaign])

  const handleGetCampaign = (params) => {
    getCampaign(params)
  }

  const tourOverview = useSelector(
    selectGuideListByKey(GUIDE_TYPE_ENUM.campaign_manager)
  )

  const [tourConfig, setTourConfig] = useState(null)

  useEffect(() => {
    if (
      getIsGuideByKey(GUIDE_TYPE_ENUM.campaign_manager) &&
      tourOverview?.length
    ) {
      setTourConfig(tourOverview)
      setIsGuideByKey(GUIDE_TYPE_ENUM.campaign_manager)
    }
  }, [tourOverview])

  return (
    <MainLayout>
      <Tour
        steps={tourConfig}
        isOpen={tourConfig != null}
        closeWithMask={false}
        lastStepNextButton={
          <Button size="small" className="btn--rounder" variant="contained">
            {t('common.btn.close')}
          </Button>
        }
        accentColor="#05ae34"
        onRequestClose={() => setTourConfig(null)}
      />

      {loading && <Loading />}
      <ListCampaignComponent
        handleGetCampaign={(params) => handleGetCampaign(params)}
        getCampaign={(params) => getCampaign(params)}
        data={campaignData}
        isShowFilter={true}
      />
      <MessengerButton />
    </MainLayout>
  )
}

CampaignView.propTypes = {}

CampaignView.defaultProps = {}

export default CampaignView
