import React from 'react'
import { useTranslation } from 'react-i18next'

// Hàm tính khoảng thời gian còn lại
const calculateTimeRemaining = (sendTime) => {
  const now = new Date()
  const targetTime = new Date(sendTime)
  const diff = targetTime - now

  if (diff <= 0) return null // Không cần tính khi đã trễ

  const hours = Math.floor(diff / (1000 * 60 * 60))
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))

  if (hours > 0) {
    return `${hours} hour(s) ${minutes} minute(s)`
  } else {
    return `${minutes} minute(s)`
  }
}

// Hàm xác định trạng thái thời gian
const determineTimeStatus = (sendTime) => {
  if (!sendTime) return 'unscheduled' // Không có thời gian gửi
  const now = new Date()
  const targetTime = new Date(sendTime)

  if (targetTime < now) {
    return 'overdue' // Trễ lịch
  } else if ((targetTime - now) / (1000 * 60) <= 30) {
    return 'upcoming' // Sắp tới
  } else {
    return 'scheduled' // Lịch gửi
  }
}

// Hàm xác định màu sắc dựa trên trạng thái
const getStatusColors = (status) => {
  switch (status) {
    case 'overdue':
      return { backgroundColor: '#ffcccc', textColor: '#ff0000' } // Đỏ nhạt
    case 'upcoming':
      return { backgroundColor: '#fff4cc', textColor: '#ffa500' } // Cam nhạt
    case 'scheduled':
      return { backgroundColor: '#ccffcc', textColor: '#008000' } // Xanh lá nhạt
    case 'unscheduled':
      return { backgroundColor: '#e0e0e0', textColor: '#808080' } // Xám
    default:
      return { backgroundColor: '#ffffff', textColor: '#000000' } // Trắng
  }
}

// Component chính
const TimeLabel = ({ sendTime, style }) => {
  const { t } = useTranslation();
  const status = determineTimeStatus(sendTime)
  const colors = getStatusColors(status)
  const timeRemaining = sendTime ? calculateTimeRemaining(sendTime) : null

  // Format thời gian gửi
  const formattedSendTime = sendTime
    ? new Date(sendTime).toLocaleString()
    : null

  // Nội dung hiển thị
  let displayText
  if (status === 'unscheduled') {
    displayText = `${t("campaign_management.scenario.no_scheduled_time")}`;
  } else if (status === 'overdue') {
    displayText = `${t("campaign_management.scenario.overdue")}`;
  } else if (status === 'upcoming') {
    displayText = `${t(
      "campaign_management.scenario.upcoming"
    )}: ${timeRemaining}`;
  } else {
    displayText = `${t(
      "campaign_management.scenario.scheduled_at"
    )}: ${formattedSendTime}`;
  }

  return (
    <div
      style={{
        backgroundColor: colors.backgroundColor,
        color: colors.textColor,
        padding: '8px 12px',
        borderRadius: '4px',
        display: 'inline-block',
        width: 'max-content',
        fontSize: '12px',
        ...style,
      }}
    >
      {displayText}
    </div>
  )
}

export default TimeLabel
