import React, { useEffect, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import TagPreviewItem from '../TagPreviewItem'
import FormDialog from '../FormDialog'
import CloseIcon from '@material-ui/icons/Close'
import { useDispatch } from 'react-redux'
import { showError } from '../../stores/alert/slice.alert'
import LoadingWrapper from '../LoadingWrapper'

const useStyles = makeStyles((theme) => ({
  tag: {
    backgroundColor: '#d32f2f',
    color: 'white',
    padding: '8px 12px',
    fontSize: '12px',
    borderRadius: '15px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    transition: 'background-color 0.3s ease',
  },

  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 12,
    marginRight: 12,
  },
  saveButton: {
    backgroundColor: '#E6F4EA' /* Light green background like the tag */,
    color: '#388E3C' /* Green text similar to the tag text */,
    border: '1px solid #afd0b1',

    '&:hover': {
      backgroundColor: '#D0E8D6' /* Slightly darker light green on hover */,
    },

    width: '100px',
    fontSize: '12px',
    padding: '6px',
    height: 'fit-content',
  },
  cancelButton: {
    backgroundColor: 'rgb(236, 239, 241)',
    color: '#333333' /* Dark gray text */,
    border: '1px solid rgba(96, 125, 139, 0.125)',
    '&:hover': {
      backgroundColor: '#E0E0E0' /* Slightly darker gray on hover */,
    },

    width: '80px',
    fontSize: '12px',
    padding: '6px',
    height: 'fit-content',

    marginLeft: '24px',
  },

  closeBtn: {
    color: 'rgb(96, 125, 139)',
    minWidth: '50px',
  },
}))

export const colorDir1 = [
  {
    backgroundColor: '#F0FFF4', // xanh lá nhạt
    textColor: '#008060', // xanh lá đậm
  },
  {
    backgroundColor: '#E6FFFB', // xanh ngọc nhạt
    textColor: '#00796B', // xanh ngọc đậm
  },
  {
    backgroundColor: '#FFF8E1', // vàng nhạt
    textColor: '#FFA000', // vàng cam đậm
  },
  {
    backgroundColor: '#E3F2FD', // xanh dương rất nhạt
    textColor: '#1565C0', // xanh dương đậm
  },
  {
    backgroundColor: '#FCE4EC', // hồng nhạt
    textColor: '#AD1457', // hồng đậm
  },
  {
    backgroundColor: '#FFEBEE', // đỏ nhạt
    textColor: '#C62828', // đỏ đậm
  },
  {
    backgroundColor: '#FFFDE7', // vàng rất nhạt
    textColor: '#F57F17', // vàng đậm
  },
  {
    backgroundColor: '#F3E5F5', // tím nhạt
    textColor: '#6A1B9A', // tím đậm
  },
  {
    backgroundColor: '#E0F7FA', // xanh nhạt
    textColor: '#00838F', // xanh đậm
  },
  {
    backgroundColor: '#FFF9C4', // vàng kem nhạt
    textColor: '#FBC02D', // vàng nghệ đậm
  },
  {
    backgroundColor: '#F1F8E9', // xanh lá nhạt
    textColor: '#558B2F', // xanh lá đậm
  },
  {
    backgroundColor: '#E8F5E9', // xanh lục nhạt
    textColor: '#2E7D32', // xanh lục đậm
  },
  {
    backgroundColor: '#FFFBF1', // vàng kem rất nhạt
    textColor: '#D17F00', // cam đậm
  },
  {
    backgroundColor: '#EDE7F6', // tím nhạt
    textColor: '#5E35B1', // tím đậm
  },
  {
    backgroundColor: '#FCE7F3', // hồng rất nhạt
    textColor: '#880E4F', // hồng đậm
  },
  {
    backgroundColor: '#FFF1E0', // cam nhạt
    textColor: '#D84315', // cam đậm
  },
  {
    backgroundColor: '#EBF1FF', // xanh lam rất nhạt
    textColor: '#1A73E8', // xanh lam đậm
  },
  {
    backgroundColor: '#FFFAF0', // kem nhạt
    textColor: '#CC8500', // vàng đậm
  },
  {
    backgroundColor: '#E7EFF6', // xanh xám nhạt
    textColor: '#455A64', // xanh xám đậm
  },
  {
    backgroundColor: '#FFF3E0', // cam vàng nhạt
    textColor: '#EF6C00', // cam vàng đậm
  },
  {
    backgroundColor: '#E8EAF6', // xanh xám rất nhạt
    textColor: '#3F51B5', // xanh đậm
  },
  {
    backgroundColor: '#F5E8FF', // tím hồng nhạt
    textColor: '#7B1FA2', // tím hồng đậm
  },
  {
    backgroundColor: '#EFF8E1', // xanh lá nhạt
    textColor: '#33691E', // xanh lá rất đậm
  },
  {
    backgroundColor: '#FFEDF0', // đỏ hồng nhạt
    textColor: '#B71C1C', // đỏ rất đậm
  },

  {
    backgroundColor: '#FFF5F7', // hồng rất nhạt
    textColor: '#C2185B', // hồng đậm
  },
  {
    backgroundColor: '#F0F4C3', // xanh lá chanh nhạt
    textColor: '#827717', // xanh lá đậm
  },
  {
    backgroundColor: '#FFEBEE', // đỏ nhạt
    textColor: '#D32F2F', // đỏ đậm
  },
  {
    backgroundColor: '#F1F8E9', // xanh lá rất nhạt
    textColor: '#33691E', // xanh lá đậm
  },
  {
    backgroundColor: '#E1F5FE', // xanh dương nhạt
    textColor: '#0277BD', // xanh dương đậm
  },
  {
    backgroundColor: '#FCE4EC', // hồng nhạt
    textColor: '#E91E63', // hồng đậm
  },
  {
    backgroundColor: '#FFF3E0', // vàng cam nhạt
    textColor: '#FB8C00', // cam đậm
  },
  {
    backgroundColor: '#E0F7FA', // xanh ngọc nhạt
    textColor: '#0097A7', // xanh ngọc đậm
  },
  {
    backgroundColor: '#F9FBE7', // vàng xanh rất nhạt
    textColor: '#827717', // vàng xanh đậm
  },
  {
    backgroundColor: '#FFF8E1', // vàng nhạt
    textColor: '#F57C00', // cam đậm
  },
  {
    backgroundColor: '#F1EFFF', // tím rất nhạt
    textColor: '#512DA8', // tím đậm
  },

  {
    backgroundColor: '#ECEFF1', // xanh xám nhạt
    textColor: '#607D8B', // xanh xám đậm
  },
  {
    backgroundColor: '#F3E5F5', // tím nhạt
    textColor: '#8E24AA', // tím đậm
  },
  {
    backgroundColor: '#F0F4C3', // vàng xanh nhạt
    textColor: '#9E9D24', // xanh vàng đậm
  },
  {
    backgroundColor: '#E3F2FD', // xanh dương nhạt
    textColor: '#1E88E5', // xanh dương đậm
  },
  {
    backgroundColor: '#E8F5E9', // xanh lục nhạt
    textColor: '#388E3C', // xanh lục đậm
  },
  {
    backgroundColor: '#F9FBE7', // vàng chanh rất nhạt
    textColor: '#C0CA33', // xanh vàng đậm
  },
  {
    backgroundColor: '#FFEBEE', // đỏ nhạt
    textColor: '#D50000', // đỏ đậm
  },
  {
    backgroundColor: '#FCE4EC', // hồng nhạt
    textColor: '#C2185B', // hồng đậm
  },
  {
    backgroundColor: '#E0F2F1', // xanh ngọc nhạt
    textColor: '#00695C', // xanh ngọc đậm
  },
  {
    backgroundColor: '#FFFDE7', // vàng rất nhạt
    textColor: '#FBC02D', // vàng đậm
  },
  {
    backgroundColor: '#FFF3E0', // cam nhạt
    textColor: '#F57C00', // cam đậm
  },
  {
    backgroundColor: '#F3E5F5', // tím nhạt
    textColor: '#AB47BC', // tím đậm
  },
  {
    backgroundColor: '#FFEBEE', // đỏ nhạt
    textColor: '#C62828', // đỏ đậm
  },
]

export const getRandomTag = (tagsArray) => {
  const randomIndex = Math.floor(Math.random() * tagsArray.length)
  return tagsArray[randomIndex]
}

const TagsModal = ({
  isOpen,
  onClose,
  selectedTags,
  tagsObj,
  customer,
  handleRemoveTags,
  type,
}) => {
  const [removingTags, setRemovingTags] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useDispatch()

  const classes = useStyles()

  const onSubmitRemoveTags = async () => {
    try {
      setIsLoading(true)
      await handleRemoveTags(customer, removingTags)
    } catch (error) {
      dispatch(showError(error.message))
    } finally {
      setIsLoading(false)
      onClose()
    }
  }

  return (
    <FormDialog isOpen={isOpen} width="auto">
      <LoadingWrapper isOpen={isLoading}>
        <div style={{ padding: '6px 10px 10px', maxWidth: '560px' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              borderBottom: '0.2px solid #f6efef',
            }}
          >
            <h4
              style={{
                fontSize: '18px',
                fontWeight: 'bold',
                padding: '12px 6px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                color: '#1976d2', 
              }}
              className={classes.title}
            >
              {type === 'bombotTag' ? 'Bombot Tags' : 'Facebook Tags'}
            </h4>

            <Button className={classes.closeBtn}>
              <CloseIcon onClick={onClose} />
            </Button>
          </div>
          <div
            style={{
              display: 'flex',
              gap: '16px',
              alignItems: 'flex-start',
              justifyContent: 'start',
              flexWrap: 'wrap',
              overflow: 'auto',
              maxHeight: '60vh',
              maxWidth: '580px',
              minWidth: '260px',
              padding: '10px 8px',
              borderBottom: '0.2px solid #f6efef',
            }}
          >
            {selectedTags?.length > 0 &&
              selectedTags.map((tag, index) => {
                const tagContent =
                  type === 'bombotTag'
                    ? tagsObj.bombotTags[tag]
                    : tagsObj.fbTags[tag]
                return (
                  <TagPreviewItem
                    key={index}
                    background={
                      tagContent?.backgroundColor || `#${tagContent?.color}`
                    }
                    color={tagContent?.textColor || '#FFF'}
                    name={tagContent?.name}
                    size="medium"
                    onRemove={
                      type === 'bombotTag'
                        ? () => {
                            setRemovingTags((prev) => {
                              const prevTmp = [...prev]
                              prevTmp.push(tag)
                              return prevTmp
                            })
                          }
                        : null
                    }
                    onUnRemove={
                      type === 'bombotTag'
                        ? () => {
                            setRemovingTags((prev) => {
                              let prevTmp = prev.filter((id) => id !== tag)
                              return prevTmp
                            })
                          }
                        : null
                    }
                  />
                )
              })}
          </div>

          <div className={classes.footer}>
            {type === 'bombotTag' && (
              <Button
                className={classes.saveButton}
                onClick={onSubmitRemoveTags}
              >
                Save
              </Button>
            )}
            <Button className={classes.cancelButton} onClick={() => onClose()}>
              Cancel
            </Button>
          </div>
        </div>
      </LoadingWrapper>
    </FormDialog>
  )
}

export default TagsModal
