import React from 'react'

const LegendItem = ({ title, legendColor, number, percent }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        padding: '4px 0',
      }}
    >
      <span
        style={{
          width: '12px',
          height: '12px',
          backgroundColor: legendColor,
          display: 'inline-block',
          marginRight: '8px',
          marginTop: '4px',
        }}
      ></span>

      <div>
        <p
          style={{
            color: '#9095a0',
            fontSize: '10px',
            fontWeight: 600,
            whiteSpace: 'nowrap',
          }}
        >
          {title}
        </p>
        <p
          style={{
            color: '#171a1f',
            fontSize: '14px',
            fontWeight: 500,
          }}
        >
          {number}
        </p>
        <p
          style={{
            fontSize: '10px',
            fontWeight: 400,
            color: '#A5D6A7',
            marginTop: '4px',
          }}
        >
          {percent}
        </p>
      </div>
    </div>
  )
}

export default LegendItem
